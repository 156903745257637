<template>
    <div id="booking-calendar">
      <div v-if="isMobile" class="mobile-calendar">
        <div class="d-flex align-center justify-space-around">
          <v-btn @click="prev" x-small fab color="primary">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div @click="datePickerModel = !datePickerModel">
            {{ currentDate }}
          </div>
          <v-btn @click="next" x-small fab color="primary">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-calendar
        ref="calendar"
        color="primary"
        v-model="calendarValue"
        :weekdays="[1,2,3,4,5,6,0]"
        :type="$store.getters.calendarMode"
        locale="fr"
        first-time="09:00"
        :interval-count="10"
        :interval-height="95"
        :events="calendarEvents"
        @click:event="(evt) => $store.dispatch('openRoomDialog', {event: evt, eventToCreate: null})"
        @click:time="(evt) => $store.dispatch('openRoomDialog', {event: null, eventToCreate: {date: evt.date, start:{hour: evt.hour, minute: evt.minute}}})"
        @click:date="(evt) => $store.dispatch('openRoomDialog', {event: null, eventToCreate: {date: evt.date}})"
      >
        <template #event="{event, eventParsed}">
          <div
            class="pa-2 noselect"
            v-if="$store.getters.calendarMode !== 'month'"
          >
            <div class="d-flex align-center">
              <div class="overflow-hidden">
                <b>{{event.name}}</b>
              </div>
              <v-spacer />
              <v-tooltip bottom>
                <template #activator="{on, attrs}">
                  <img
                    height="13px"
                    width="auto"
                    :src="require(`@/assets/flag-${event.locale}.png`)"
                    v-on="on"
                    v-bind="attrs"
                  />
                </template>
                <span v-if="event.locale === 'fr'">Français</span>
                <span v-else-if="event.locale === 'en'">Anglais</span>
              </v-tooltip>
            </div>
            <div class="d-flex align-center">
              <v-icon
                color="white"
                small
                class="pb-1"
              >
                mdi-account-group
              </v-icon>
              <div class="d-flex align-center ml-2">
                {{event.pax}} pax
              </div>
            </div>
            <div
              v-if="event.paid"
              class="d-flex align-center"
            >
              <v-icon
                small
                color="#068400"
              >
                mdi-currency-eur
              </v-icon>
              <div class="ml-2">
                Payé
              </div>
            </div>
            <div
              v-else-if="!event.paid"
              class="d-flex align-center"
            >
              <v-icon
                small
                color="error"
              >
                mdi-currency-eur-off
              </v-icon>
              <div class="ml-2">
                Non payé
              </div>
            </div>
        </div>
          <div v-else class="px-2">
            <b>{{event.name}}</b> <span>{{eventParsed.start.time}} - {{event.pax}} pax</span>
          </div>
        </template>
      </v-calendar>
      <v-dialog v-model="datePickerModel">
        <v-date-picker v-model="calendarValue" first-day-of-week="1" locale="fr-FR" />
      </v-dialog>
      <v-bottom-navigation app v-if="isMobile" v-model="calendarMode">
        <v-btn value="day">
          Jour
          <v-icon>mdi-view-day-outline</v-icon>
        </v-btn>
        <v-btn value="4day">
          4 Jours
          <v-icon>mdi-view-week-outline</v-icon>
        </v-btn>
        <v-btn value="week">
          Semaine
          <v-icon>mdi-view-module-outline</v-icon>
        </v-btn>
        <v-btn value="month">
          Mois
          <v-icon>mdi-view-comfy-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import moment from 'moment';

export default {
    data(){
      return {
        isMobile,
        moment,
        datePickerModel: false
      }
    },
    computed: {
        currentDate(){
          let date = this.$store.getters.calendarValue ? moment(this.$store.getters.calendarValue) : moment();
          if(['week', '4day'].includes(this.$store.getters.calendarMode)){
            date = moment(date).locale('fr').startOf('week').format('DD/MM/YYYY') + ' - ' + moment(date).locale('fr').endOf('week').format('DD/MM/YYYY')
          } else if (this.$store.getters.calendarMode === 'month'){
              date = moment(date).locale('fr').format('MM/YYYY')
          } else {
            date = moment(date).format('DD/MM/YYYY')
          }
          return date;
        },
        calendarValue: {
            get(){
                return this.$store.getters.calendarValue
            },
            set(val){
                this.$store.commit('setCalendarValue', val)
            }
        },
        calendarMode: {
          get(){
            return this.$store.getters.calendarMode
          },
          set(val){
            this.$store.commit('setCalendarMode', val);
          }
        },
        calendarEvents(){
            let events = []
            if(this.$store.getters.events !== undefined && this.$store.getters.events.length){
              this.$store.getters.events.forEach(event => {
                  if(event && event.start){
                        let start = `${this.$store.getters.moment(event.date).format('YYYY-MM-DD')} ${event.start.replace('h',':')}`
                        let endTime = `${this.$store.getters.moment(event.date).format('YYYY-MM-DD')} ${event.end.replace('h',':')}`
                        let color = '#1e2f53';

                        if(event.title === 'Salle de l\'Orangerie'){
                          color = "orange"
                        }

                        if(event.cancelled === true){
                          color = '#2b2b2b'
                        }

                        events.push({
                            name: event.name,
                            start: `${start}`,
                            end: endTime,
                            color,
                            pax: event.pax,
                            paid: event.paid,
                            title: event.title,
                            id: event._id,
                            locale: event.locale,
                            cancelled: event.cancelled
                        })
                            
                    }
              })
            }
            return events
        }
    },
    methods: {
      prev(){
        this.$refs.calendar.prev()
      },
      next(){
        this.$refs.calendar.next()
      },
    }
}
</script>
// Newest on top
export default [
    {
        date: '27/09/2022',
        updates: [
            'Horaires étendus de 8h à 20h',
            'Bugfix écran blanc entre Salles de séminaire et Visites',
            'Désactivation du drag and drop',
            'Les réservations Groupe sont séparées des réservations individuelles',
            'Les réservations annulées ne sont plus comptabilisées dans le total du pax et des réservations'
        ]
    },
    {
        date: '04/08/2022',
        updates: [
            'Modification du système d\'authentification',
            'Liaison avec le Back Office général',
            'Gestion fine des visites depuis le back office',
            'Mise à jour des données directes entre navigateurs connectés à l\'application',
            'Correction de bugs mineurs'
        ]
    },
    {
        date: '09/06/2022',
        updates: [
            'Plus besoin de recharger après mise à jour',
            'Possibilité de changer la visite pour chaque réservation',
            'Langue allemande disponible',
            'Correction du non affichage des notes sur une longue durée',
        ]
    }
]
<template>
    <v-bottom-sheet
        width="100%"
        style="overflow: auto"
        v-model="dialog"
    >
        <div style="background-color: white">
            <v-toolbar
                dark
                color="primary"
                style="flex: 0"
            >
                <span v-if="selectedEvent">
                    Réservation<br />
                    <span class="text-capitalize">
                        {{moment(selectedEvent.start).locale('fr').format('dddd D MMMM YYYY')}}
                    </span>
                </span>
                <span v-else-if="eventToCreate">
                    Réservation<br />
                    <span v-if="eventToCreate.start" class="text-capitalize"> | {{ moment(eventToCreate.start).locale('fr').format('dddd D MMMM YYYY')}}</span>
                </span>
                <v-spacer />
                <v-icon
                    color="error"
                    v-if="selectedEvent"
                    @click="deleteRoom"
                >
                    mdi-delete
                </v-icon>
                <v-icon class="ml-4" @click="$store.commit('setHotelRoomDialog', false)">
                    mdi-close
                </v-icon>
            </v-toolbar>
            <v-row
                v-if="selectedEvent || eventToCreate"
                class="mt-4 px-4"
            >
                <v-col cols="12">
                    <v-menu
                        v-if="selectedEvent"
                        offset-y
                    >
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-if="selectedEvent"
                                v-model="selectedEventParsedStart"
                                label="Date d'arrivée"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            first-day-of-week="1"
                            v-if="selectedEvent"
                            v-model="selectedEvent.start"
                        />
                    </v-menu>
                    <v-menu v-if="eventToCreate" offset-y>
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-if="eventToCreate"
                                v-model="eventToCreateParsedStart"
                                label="Date d'arrivée"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            v-if="eventToCreate"
                            v-model="eventToCreate.start"
                        />
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    <v-menu
                        v-if="selectedEvent"
                        offset-y
                    >
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-if="selectedEvent"
                                v-model="selectedEventParsedEnd"
                                label="Date de départ"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            first-day-of-week="1"
                            v-if="selectedEvent"
                            v-model="selectedEvent.end"
                        />
                    </v-menu>
                    <v-menu v-if="eventToCreate" offset-y>
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-if="eventToCreate"
                                v-model="eventToCreateParsedEnd"
                                label="Date de départ"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            v-if="eventToCreate"
                            v-model="eventToCreate.end"
                        />
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider class="my-4" />
            <div v-if="selectedEvent">
                <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                    <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                    <div class="ml-2">Détails {{  selectedEvent.source === 'web' ? '- Réservation Web' : '' }}</div>
                    <v-spacer />
                    <v-tooltip bottom>
                        <template #activator="tooltip">
                            <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                <template #activator="menu">
                                    <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="selectedEvent.cancelled ||selectedEvent.noShow ? 'error' : '#999'">
                                        <v-icon v-if="selectedEvent.cancelled === true">mdi-cancel</v-icon>
                                        <v-icon v-else-if="selectedEvent.noShow === true">mdi-emoticon-cry-outline</v-icon>
                                        <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-checkbox label="No show" v-model="selectedEvent.noShow"></v-checkbox>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-checkbox label="Annulé" v-model="selectedEvent.cancelled"></v-checkbox>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <span>Statut</span>
                    </v-tooltip>
                </v-toolbar>
                <v-row class="mt-4 px-4">
                    <v-col cols="12" class="d-flex align-center justify-end">
                        <v-switch color="success" :label="selectedEvent.paid ? 'Payé' : 'Non payé'" v-model="selectedEvent.paid" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Nom"
                            v-model="selectedEvent.name"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Prénom"
                            v-model="selectedEvent.firstName"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.email"
                            label="Email"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.phone"
                            label="Téléphone"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.address"
                            label="Adresse"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.zipCode"
                            label="Code postal"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.city"
                            label="Ville"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="selectedEvent.country"
                            label="Pays"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="selectedEvent.adultPax" label="Adultes" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="selectedEvent.childrenPax" label="Enfants" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="selectedEvent.petsPax" label="Animaux" />
                    </v-col>
                    <b>Tarif:</b>
                    <v-col cols="12">
                        <v-radio-group v-model="selectedEvent.rate">
                            <v-radio value="junior" label="Long Bois Suite Junior - 2 personnes (350€/jour)" />
                            <v-radio value="jolyrouge" label="Joly Rouge - 4 personnes (450€/jour)" />
                            <v-radio value="pepindoree" label="Pépin Dorée - 6 personnes (550€/jour)" />
                            <v-radio value="pommecheval" label="Pomme de Cheval et Pomme de Suie - 10 personnes (650€/jour)" />
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="selectedEvent.notes"
                            label="Notes"
                        />
                    </v-col>
                    <v-col cols="12" class="d-flex align-end">
                        <div>
                            <b>Tarif: {{ total }}</b>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div v-else-if="eventToCreate">
                <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                    <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                    <div class="ml-2">Détails</div>
                    <v-spacer />
                    <v-tooltip bottom>
                        <template #activator="tooltip">
                            <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                <template #activator="menu">
                                    <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="eventToCreate.cancelled || eventToCreate.noShow ? 'error' : '#999'">
                                        <v-icon v-if="eventToCreate.noShow === true">mdi-emoticon-cry-outline</v-icon>
                                        <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-checkbox label="No show" v-model="eventToCreate.noShow"></v-checkbox>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <span>Statut</span>
                    </v-tooltip>
                </v-toolbar>
                <v-row class="mt-4 px-4">
                    <v-col cols="8">
                        <v-text-field
                            label="Nom"
                            v-model="eventToCreate.name"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Prénom"
                            v-model="eventToCreate.firstName"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.email"
                            label="Email"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.phone"
                            label="Téléphone"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.address"
                            label="Adresse"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.zipCode"
                            label="Code postal"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.city"
                            label="Ville"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="eventToCreate.country"
                            label="Pays"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="eventToCreate.adultPax" label="Adultes" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="eventToCreate.childrenPax" label="Enfants" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="number" min="0" v-model.number="eventToCreate.petsPax" label="Animaux" />
                    </v-col>
                    <b>Tarif:</b>
                    <v-col cols="12">
                        <v-radio-group v-model="eventToCreate.rate">
                            <v-radio value="junior" label="Long Bois Suite Junior - 2 personnes (350€/jour)" />
                            <v-radio value="jolyrouge" label="Joly Rouge - 4 personnes (450€/jour)" />
                            <v-radio value="pepindoree" label="Pépin Dorée - 6 personnes (550€/jour)" />
                            <v-radio value="pommecheval" label="Pomme de Cheval et Pomme de Suie - 10 personnes (650€/jour)" />
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="eventToCreate.notes"
                            label="Notes"
                        />
                    </v-col>
                    <v-col cols="12" class="d-flex align-end">
                        <div>
                            <b>Tarif: {{ total }}</b>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex py-6 justify-space-around">
                <v-btn
                    color="error"
                    @click="dialog = false"
                >
                    Annuler
                </v-btn>
                <v-btn
                    color="success"
                    @click="selectedEvent ? $store.dispatch('updateHotelRoom') : $store.dispatch('createHotelRoom')"
                    class="ml-4"
                >
                    Sauvegarder
                </v-btn>
            </div>
        </div>
    </v-bottom-sheet>
</template>

<script>
import moment from 'moment';
export default {
    data(){
        return {
            customToolbar: [ 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ],
            startTimePicker: false,
            endTimePicker: false,
        }
    },
    computed: {
        total(){
            let rate = 0;
            let event = this.selectedEvent ? {...this.selectedEvent} : {...this.eventToCreate};
            let totalDays = moment(event.end).diff(moment(event.start), 'days');
            if(event.rate === 'junior'){
                rate += 350 * totalDays;
            } else if (event.rate === 'jolyrouge') {
                rate += 450 * totalDays;
            } else if (event.rate === 'pepindoree'){
                rate += 550 * totalDays;
            } else if (event.rate === 'pommecheval'){
                rate += 650 * totalDays;
            }
            if(event.adultPax){
                rate += totalDays * event.adultPax * 2.3;
            }
            if(event.petsPax){
                rate += event.petsPax * totalDays * 20;
            }
            return rate.toFixed(2);
        },
        eventToCreateParsedStart(){
            return this.moment(this.eventToCreate?.start).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedStart(){
            return this.moment(this.selectedEvent?.start).locale('fr').format('D MMM YYYY')
        },
        eventToCreateParsedEnd(){
            return this.moment(this.eventToCreate?.end).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedEnd(){
            return this.moment(this.selectedEvent?.end).locale('fr').format('D MMM YYYY')
        },
        dialog: {
            get(){
                return this.$store.getters.hotelRoomDialog
            },
            set(val){
                this.$store.commit('setHotelRoomDialog', val)
            }
        },
        selectedEvent: {
            get(){
                return this.$store.getters.selectedEvent
            },
            set(val){
                this.$store.commit('setSelectedEvent', val)
            }
        },
        eventToCreate: {
            get(){
                return this.$store.getters.eventToCreate
            },
            set(val){
                this.$store.commit('setEventToCreate', val)
            }
        },
        moment(){
            return this.$store.getters.moment
        }
    },
    methods: {
        deleteRoom(){
            let confirm = window.confirm('Êtes-vous sûr(e) de vouloir supprimer cette réservation ?')
            if(confirm){
                this.$store.dispatch('deleteRoom', this.selectedEvent)
            }
        }
    }
}
</script>
<template>
    <v-bottom-sheet
        width="100%"
        v-model="dialog"
        v-if="eventToCreate"
    >
        <div style="background-color: white">
            <v-toolbar
                dark
                color="primary"
                style="flex: 0"
            >
                    <div>
                        {{eventToCreate.tour.frTitle}}<br />
                        <span v-if="eventToCreate.date" class="text-capitalize">
                            {{ moment(eventToCreate.date).locale('fr').format('dddd D MMMM YYYY')}}
                        </span>
                        <span v-if="eventToCreate.slot"> - {{ eventToCreate.slot }}</span>
                    </div>
            </v-toolbar>
            <v-row
                class="mt-4 px-4"
            >
                <v-col cols="12">
                    <v-select
                        :menu-props="{offsetY: true}"
                        v-model="eventToCreate.tour"
                        label="Visite"
                        :items="toursItems"
                    />
                </v-col>
                <v-col cols="12">
                    <v-menu offset-y>
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-model="eventToCreateParsedDate"
                                :label="eventToCreate.tour.isNote ? 'Date de début' : 'Date'"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            v-model="eventToCreate.date"
                        />
                    </v-menu>
                </v-col>
                <v-col cols="12" class="px-4" v-if="eventToCreate.tour.isNote">
                    <v-menu offset-y>
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-on="on"
                                v-bind="attrs"
                                v-model="eventToCreateParsedEndDate"
                                label="Date de fin"
                            />
                        </template>
                        <v-date-picker
                            no-title
                            locale="fr"
                            v-model="eventToCreate.endDate"
                        />
                    </v-menu>
                </v-col>
                <v-col cols="12" class="px-4">
                    <v-menu
                        bottom
                        offset-y
                        v-if="eventToCreate.slot && !eventToCreate.tour.isNote"
                        :close-on-content-click="false"
                        v-model="timePicker"
                    >
                        <template #activator="{on, attrs}">
                            <v-text-field
                                v-model="eventToCreate.slot"
                                label="Heure"
                                v-on="on"
                                v-bind="attrs"
                                @change="parseTime"
                                @input="(val) => !val ? eventToCreate.slot = '09:00' : null"
                            />
                        </template>
                        <v-time-picker
                            v-model="parsedCreateSlot"
                            format="24hr"
                            @click:minute="timePicker = false"
                            :allowed-hours="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18]"
                            :allowed-minutes="[0, 15, 30, 45]"
                        />
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider class="my-4" />
            <div
                class="d-flex px-4"
                v-if="(!eventToCreate.tour.isNote && !eventToCreate.tour.freeTitle)"
            >
                <h4>Réservations</h4>
                <v-spacer />
                <v-btn
                    small
                    color="primary"
                    @click="$store.dispatch('addBooking')"
                >
                    <v-icon
                        small
                        class="mr-2"
                    >
                        mdi-plus
                    </v-icon>
                    Créer
                </v-btn>
            </div>
            <div class="px-4" v-if="!eventToCreate.tour.isNote && !eventToCreate.tour.freeTitle">
                <EventBookings
                    v-for="(booking, index) in eventToCreate.bookings"
                    :key="index"
                    :index="index"
                    type="create"
                    :defaultSlot="eventToCreate.slot"
                />
            </div>
            <div v-else style="height: 65%" class="px-4">
                <v-row>
                    <v-col :cols="eventToCreate.tour.isNote ? 12 : 10">
                        <v-text-field
                            label="Titre"
                            v-model="eventToCreate.freeTitle"
                        />
                    </v-col>
                    <v-col cols="2" v-if="eventToCreate.tour.freeTitle && !eventToCreate.tour.isNote">
                        <div class="d-flex align-center">
                            <v-text-field style="width: 35%" label="Durée" type="number" v-model.number="hours" min="0" />
                            <div style="flex: 1">h</div>
                            <v-text-field style="width: 35%" type="number" v-model.number="minutes" min="0" max="59" />
                        </div>
                    </v-col>
                </v-row>
                <vue-editor
                    style="height: 100%"
                    v-model="eventToCreate.notes"
                    :editorToolbar="customToolbar"
                />
            </div>
            <div class="d-flex py-6 justify-space-around">
                <v-btn
                    color="error"
                    @click="dialog = false"
                >
                    Annuler
                </v-btn>
                <v-btn
                    color="success"
                    @click="$store.dispatch('createEvent')"
                    class="ml-4"
                >
                    Sauvegarder
                </v-btn>
            </div>
        </div>
    </v-bottom-sheet>
</template>

<script>
import EventBookings from '@/components/EventBookings.vue'
export default {
    components: {
        EventBookings
    },
    data(){
        return {
            customToolbar: [ 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ],
            timePicker: false
        }
    },
    computed: {
        toursItems(){
            return this.$store.getters.tours.map(tour => {
                return {
                    text: tour.frTitle,
                    value: tour
                }
            });
        },
        eventToCreateParsedDate(){
            return this.moment(this.eventToCreate?.date).locale('fr').format('D MMM YYYY')
        },
        eventToCreateParsedEndDate(){
            return this.moment(this.eventToCreate?.endDate).locale('fr').format('D MMM YYYY')
        },
        hours: {
            get(){
                return this.eventToCreate?.duration?.minutes ? this.eventToCreate.duration.hours : 1
            },
            set(val){
                this.eventToCreate.duration.hours = val
            }
        },
        minutes: {
            get(){
                return this.eventToCreate?.duration?.minutes ? this.eventToCreate.duration.minutes : 0
            },
            set(val){
                this.eventToCreate.duration.minutes = val
            }
        },
        parsedCreateSlot: {
            get(){
                if(this.eventToCreate){
                    return this.eventToCreate.slot.replace('h', ':')
                }
                return '09:00'
            },
            set(val){
                this.eventToCreate.slot = val.replace(':', 'h')
            }
        },
        dialog: {
            get(){
                return this.$store.getters.bookingCreateDialog
            },
            set(val){
                this.$store.commit('setBookingCreateDialog', val)
            }
        },
        eventToCreate: {
            get(){
                return this.$store.getters.eventToCreate
            },
            set(val){
                if(val){
                    if(!val.tour.freeTitle){
                        if(val.bookings === undefined){
                            val.bookings = []
                        }
                    }
                    if(val?.bookings?.length && !val.tour.freeTitle){
                        val.bookings.forEach(booking => {
                            booking.tour_id = val.tour._id
                            booking.category_id = val.tour.category
                            booking.title = val.tour.frTitle
                        })
                    }
                    if(val.tour.freeTitle){
                        val.notes = ''
                        delete val.bookings
                    }
                    this.$store.commit('setEventToCreate', val)
                } else {
                    this.$store.commit('setEventToCreate', val)
                }
            }
        },
        moment(){
            return this.$store.getters.moment
        }
    },
    methods: {
        parseTime(value){
            let val = value.replace(/\D/g,'')
            if(!val){
                val = '0900'
            }
            if(val.length < 4){
                val += '0'
                this.parseTime(val)
                return
            }
            if(val.length > 4){
                val = val.slice(0, 4)
            }
            let hours = val.slice(0,2)
            let minutes = val.slice(2)
            if(parseInt(hours, 10) < 9){
                hours = '09';
            }
            if(parseInt(hours, 10) > 18){
                hours = '18';
            }
            if(parseInt(minutes, 10) < 0){
                minutes = '00';
            }
            if(parseInt(minutes, 10) > 59){
                minutes = '59';
            }
            let slot = hours+'h'+minutes
            
            this.eventToCreate.slot = slot
        },
    }
}
</script>
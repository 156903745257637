import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import App from './App.vue'
import _axios from './plugins/axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify'
import router from './router'
import Vue2Editor from "vue2-editor";

import './sass/style.sass'
import store from './store'

Vue.config.productionTip = false

Vue.use(Vue2Editor);
Vue.use(VueAxios, _axios);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

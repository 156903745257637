<template>
    <v-container id="booking-container" class="pa-0 overflow-y-hidden" fluid>
        <RoomsSideColumn v-if="!isMobile" class="d-none d-md-block" />
        <RoomsCalendar ref="roomsCalendarModule" />
        <RoomsDialog v-if="!isMobile" />
        <RoomsDialogMobile v-if="isMobile" />
  </v-container>
</template>

<script>
import RoomsSideColumn from '@/components/RoomsSideColumn.vue';
import RoomsCalendar from '@/components/RoomsCalendar.vue';
import RoomsDialog from '@/components/RoomsDialog.vue';
import RoomsDialogMobile from '@/components/RoomsDialogMobile.vue';
import { isMobile } from 'mobile-device-detect';

export default {
    components: {
        RoomsCalendar,
        RoomsSideColumn,
        RoomsDialog,
        RoomsDialogMobile
    },
    data(){
        return {
            isMobile
        }
    },
    computed: {
        calendarValue(){
            return this.$store.getters.calendarValue
        }
    },
    watch: {
        calendarValue(newVal, oldVal){
            let moment = this.$store.getters.moment
            let oldValue = oldVal ? oldVal : moment()
            let newValue = newVal ? newVal : moment()
            if(moment(newValue).format('MM-YYYY') !== moment(oldValue).format('MM-YYYY')){
                this.axios.get(`rooms?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
                .then(res => {
                    this.$store.commit('setEvents', res.data.result)
                })
            }
        }
    },
    mounted(){
        this.$store.commit('setCalendarMode', isMobile ? 'day' : 'week');
        this.axios.get(`rooms?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
        .then(res => {
            if(res.data){
                this.$store.commit('setEvents', res.data.result)
            }
        })
    },
}
</script>
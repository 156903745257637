<template>
    <v-dialog
        v-if="selectedEvent"
        width="50%"
        class="overflow-y-hidden"
        v-model="dialog"
    >
        <v-card
            class="d-flex flex-column overflow-y-hidden"
            height="90vh"
        >
            <v-toolbar
                dark
                color="primary"
                height="60px"
                style="flex: 0"
            >
                <v-card-title>
                    {{selectedEvent.tour_id.frTitle}} | {{moment(selectedEvent.date).locale('fr').format('dddd D MMMM YYYY')}} - {{selectedEvent.slot}}
                </v-card-title>
                <v-spacer />
                <v-icon
                    color="error"
                    v-if="(selectedEvent.tour_id.isNote || selectedEvent.tour_id.freeTitle)"
                    @click="deleteBooking"
                >
                    mdi-delete
                </v-icon>
                <v-icon class="ml-4" @click="$store.commit('setBookingEditDialog', false)">
                    mdi-close
                </v-icon>
            </v-toolbar>
            <v-card-text class="flex overflow-y-auto">
                <v-row
                    class="mt-4"
                >
                    <v-col cols="4">
                        <v-select
                            :menu-props="{offsetY: true}"
                            v-model="selectedEvent.tour_id._id"
                            label="Visite"
                            :items="toursItems"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-menu offset-y>
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-model="selectedEventParsedDate"
                                    :label="selectedEvent.tour_id.isNote ? 'Date de début' : 'Date'"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                first-day-of-week="1"
                                v-if="selectedEvent"
                                v-model="selectedEvent.date"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="4" v-if="selectedEvent.tour_id.isNote">
                        <v-menu offset-y>
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-model="selectedEventParsedEndDate"
                                    label="Date de fin"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                v-model="selectedEvent.endDate"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="4">
                        <v-menu
                            bottom
                            offset-y
                            v-if="!selectedEvent.tour_id.isNote"
                            :close-on-content-click="false"
                            v-model="timePicker"
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-model="selectedEvent.slot"
                                    label="Heure"
                                    v-on="on"
                                    v-bind="attrs"
                                    @change="parseTime"
                                />
                            </template>
                            <v-time-picker
                                v-model="parsedSelectedSlot"
                                format="24hr"
                                @click:minute="timePicker = false"
                                :allowed-hours="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18]"
                                :allowed-minutes="[0, 15, 30, 45]"
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <div
                    class="d-flex"
                    v-if="!selectedEvent.tour_id.isNote && !selectedEvent.tour_id.freeTitle"
                >
                    <h4>Réservations</h4>
                    <v-spacer />
                    <v-btn
                        small
                        color="primary"
                        @click="$store.dispatch('addBooking')"
                    >
                        <v-icon
                            small
                            class="mr-2"
                        >
                            mdi-plus
                        </v-icon>
                        Créer
                    </v-btn>
                </div>
                <div v-if="!selectedEvent.tour_id.isNote && !selectedEvent.tour_id.freeTitle">
                    <EventBookings
                        v-for="(booking, index) in selectedEvent.bookings"
                        :key="index"
                        :index="index"
                        type="selected"
                        :defaultSlot="selectedEvent.slot"
                    />
                </div>
                <div v-else style="height: 65%">
                    <v-row>
                        <v-col :cols="selectedEvent.tour_id.isNote ? 12 : 10">
                            <v-text-field
                                label="Titre"
                                v-model="selectedEvent.freeTitle"
                            />
                        </v-col>
                        <v-col cols="2" v-if="selectedEvent.tour_id.freeTitle && !selectedEvent.tour_id.isNote">
                            <div class="d-flex align-center">
                                <v-text-field style="width: 35%" label="Durée" type="number" v-model.number="selectedEventHours" min="0" />
                                <div style="flex: 1">h</div>
                                <v-text-field style="width: 35%" type="number" v-model.number="selectedEventMinutes" min="0" max="59" />
                            </div>
                        </v-col>
                    </v-row>
                    <vue-editor
                        style="height: 100%"
                        v-model="selectedEvent.notes"
                        :editorToolbar="customToolbar"
                    />
                </div>
            </v-card-text>
            <v-card-actions
                style="height: 60px"
                class="d-flex justify-end"
            >
                <v-btn
                    color="error"
                    @click="dialog = false"
                >
                    Annuler
                </v-btn>
                <v-btn
                    color="success"
                    @click="$store.dispatch('updateEvent')"
                    class="ml-4"
                >
                    Sauvegarder
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBookings from '@/components/EventBookings.vue'
export default {
    components: {
        EventBookings
    },
    data(){
        return {
            customToolbar: [ 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ],
            timePicker: false
        }
    },
    computed: {
        toursItems(){
            return this.$store.getters.tours.map(tour => {
                return {
                    text: tour.frTitle,
                    value: tour._id
                }
            });
        },
        selectedEventParsedDate(){
            return this.moment(this.selectedEvent?.date).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedEndDate(){
            if(this.selectedEvent && this.selectedEvent.endDate !== undefined){
                return this.moment(this.selectedEvent?.endDate).locale('fr').format('D MMM YYYY')
            }
            return this.selectedEventParsedDate;
        },
        selectedEventHours: {
            get(){
                return this.selectedEvent.duration ? this.selectedEvent.duration.hours : 1
            },
            set(val){
                if(this.selectedEvent.duration === undefined){
                    this.selectedEvent.duration = {
                        hours: val,
                        minutes: 0
                    }
                } else {
                    this.selectedEvent.duration.hours = val
                }
            }
        },
        selectedEventMinutes: {
            get(){
                return this.selectedEvent.duration ? this.selectedEvent.duration.minutes : 0
            },
            set(val){
                if(this.selectedEvent.duration === undefined){
                    this.selectedEvent.duration = {
                        hours: 1,
                        minutes: val
                    }
                } else {
                    this.selectedEvent.duration.minutes = val
                }
            }
        },
        parsedSelectedSlot: {
            get(){
                if(this.selectedEvent && this.selectedEvent.slot){
                    return this.selectedEvent.slot.replace('h', ':')
                }
                return '09:00'
            },
            set(val){
                this.selectedEvent.slot = val.replace(':', 'h')
            }
        },
        dialog: {
            get(){
                return this.$store.getters.bookingEditDialog
            },
            set(val){
                this.$store.commit('setBookingEditDialog', val)
            }
        },
        selectedEvent: {
            get(){
                return this.$store.getters.selectedEvent
            },
            set(val){
                this.$store.commit('setSelectedEvent', {value: val})
            }
        },
        moment(){
            return this.$store.getters.moment
        }
    },
    watch: {
        'selectedEvent.tour_id._id': function(){
            this.$store.commit('setSelectedEvent', {value: this.selectedEvent});
        }
    },  
    methods: {
        parseTime(value){

            let val = value.replace(/\D/g,'')
            if(val.length < 4){
                val += '0'
                this.parseTime(val)
                return
            }
            if(val.length > 4){
                val = val.slice(0, 4)
            }
            let hours = val.slice(0,2)
            let minutes = val.slice(2)
            if(parseInt(hours, 10) < 9){
                hours = '09';
            }
            if(parseInt(hours, 10) > 18){
                hours = '18';
            }
            if(parseInt(minutes, 10) < 0){
                minutes = '00';
            }
            if(parseInt(minutes, 10) > 59){
                minutes = '59';
            }
            let slot = hours+'h'+minutes
            
            this.selectedEvent.slot = slot 
        },
        deleteBooking(){
            let confirm = window.confirm('Êtes-vous sûr(e) de vouloir supprimer cette note / évenement ?')
            if(confirm){
                this.$store.dispatch('deleteNote', this.selectedEvent)
            }
        }
    }
}
</script>
import axios from "axios";
import router from '@/router';

let config = {
  // eslint-disable-next-line
  baseURL: process.env.VUE_APP_API_BASE_URL
};

const _axios = axios.create(config);

let accessToken = localStorage.getItem('LSF_BACKOFFICE_ACCESS_TOKEN');
let refreshToken = localStorage.getItem('LSF_BACKOFFICE_REFRESH_TOKEN');

_axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    // eslint-disable-next-line
    if(originalRequest.url !== `${process.env.VUE_APP_BASE_API_URL}auth/refreshToken` && error.response.status === 401 && originalRequest._retry !== true) {
      originalRequest._retry = true;
      if(refreshToken && refreshToken !== null && refreshToken !== "null" && refreshToken !== ""){
        _axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
        await _axios.post('auth/refreshToken').then(response => {
          localStorage.setItem('LSF_BACKOFFICE_ACCESS_TOKEN', response.data.accessToken);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
          _axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
        }).catch(() => {
          refreshToken = null;
          localStorage.setItem('LSF_BACKOFFICE_REFRESH_TOKEN', null);
          router.push('/login');
        });
        return _axios(originalRequest);
      } 
      router.push('/login');
      Promise.resolve();
    }
    return Promise.reject(error);
  }
);

export default _axios;
<template>
  <div id="booking-calendar">
    <div v-if="isMobile" class="mobile-calendar">
      <div class="d-flex align-center justify-space-around">
        <v-btn @click="prev" x-small fab color="primary">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div @click="datePickerModel = !datePickerModel">
          {{ currentDate }}
        </div>
        <v-btn @click="next" x-small fab color="primary">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-calendar
      ref="calendar"
      color="primary"
      v-model="calendarValue"
      :weekdays="[1,2,3,4,5,6,0]"
      :type="$store.getters.calendarMode"
      locale="fr"
      first-time="08:00"
      :interval-count="12"
      :interval-height="95"
      :events="calendarEvents"
      @click:event="(evt) => $store.dispatch('openBookingEditDialog', {event: evt})"
      @click:time="(evt) => $store.dispatch('openBookingCreateDialog', {date: evt.date, time:{hour: evt.hour, minute: evt.minute}, tour: $store.getters.tours.find(tour => !tour.isNote)})"
      @click:date="(evt) => $store.dispatch('openBookingCreateDialog', {date: evt.date, tour: $store.getters.tours.find(tour => tour.isNote)})"
    >
      <template #event="{event, eventParsed}">
        <div
          class="px-2 noselect"
          v-if="event.tour.isNote"
        >
          {{event.freeTitle ? event.freeTitle : event.name}}
        </div>
        <div
          class="pa-2"
          v-if="event.tour.freeTitle"
        >
          <b>{{event.freeTitle ? event.freeTitle : event.name}}</b>
        </div>
        <div
          class="pa-2 noselect"
          v-else-if="$store.getters.calendarMode !== 'month'"
        >
          <div class="d-flex align-center">
            <div class="overflow-hidden">
              <v-tooltip bottom>
                <template #activator="{on, attrs}">
                  <v-icon
                    small
                    class="mr-1"
                    v-if="event.partiallyConfirmed || !event.confirmed"
                    v-on="on"
                    v-bind="attrs"
                    :color="event.partiallyConfirmed ? 'warning' : 'error'"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span v-if="event.partiallyConfirmed">
                  Partiellement confirmé
                </span>
                <span v-else-if="!event.confirmed">
                  A confirmer
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{on, attrs}">
                  <v-icon
                    small
                    class="mr-1"
                    v-if="event.partiallyNoShow === true || event.noShow === true"
                    v-on="on"
                    v-bind="attrs"
                    color="white"
                  >
                    mdi-emoticon-cry-outline
                  </v-icon>
                </template>
                <span v-if="event.partiallyNoShow">
                  No show partiel
                </span>
                <span v-else>
                  No show
                </span>
              </v-tooltip>
              <b>{{event.name}}</b>
            </div>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{on, attrs}">
                <img
                  v-if="['fr', 'en', 'de'].includes(event.locale)"
                  height="13px"
                  width="auto"
                  :src="require(`@/assets/flag-${event.locale}.png`)"
                  v-on="on"
                  v-bind="attrs"
                />
                  <v-icon
                    v-else
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-web
                  </v-icon>
              </template>
              <span v-if="event.locale === 'fr'">Français</span>
              <span v-else-if="event.locale === 'en'">Anglais</span>
              <span v-else-if="event.locale === 'de'">Allemand</span>
              <span v-else>Multilingue</span>
            </v-tooltip>
          </div>
          <div class="d-flex align-center">
            <v-icon
              color="white"
              small
              class="pb-1"
            >
              mdi-account-group
            </v-icon>
            <div class="d-flex align-center ml-2">
              {{event.pax}} pax
            </div>
          </div>
          <div
            v-if="event.bookingsAmount > 1"
            class="d-flex align-center"
          >
            <v-icon
              color="white"
              small
            >
              mdi-pound
            </v-icon>
            <div class="d-flex align-center ml-2">
              {{event.bookingsAmount}} réservations
            </div>
          </div>
          <div
            v-if="!event.partiallyPaid && event.paid"
            class="d-flex align-center"
          >
            <v-icon
              small
              color="#068400"
            >
              mdi-currency-eur
            </v-icon>
            <div class="ml-2">
              Payé
            </div>
          </div>
          <div
            v-else-if="!event.partiallyPaid && !event.paid"
            class="d-flex align-center"
          >
            <v-icon
              small
              color="error"
            >
              mdi-currency-eur-off
            </v-icon>
            <div class="ml-2">
              Non payé
            </div>
          </div>
          <div
            v-else-if="event.partiallyPaid"
            class="d-flex align-center"
          >
            <v-icon
              small
              color="warning"
            >
              mdi-currency-eur
            </v-icon>
            <div class="ml-2">
              Partiellement payé
            </div>
          </div>
        </div>
        <div v-else class="px-2">
          <b>{{event.name}}</b> <span>{{eventParsed.start.time}} - {{event.pax}} pax</span>
        </div>
      </template>
    </v-calendar>
    <v-dialog v-model="datePickerModel">
      <v-date-picker v-model="calendarValue" first-day-of-week="1" locale="fr-FR" />
    </v-dialog>
    <v-bottom-navigation app v-if="isMobile" v-model="calendarMode">
      <v-btn value="day">
        Jour
        <v-icon>mdi-view-day-outline</v-icon>
      </v-btn>
      <v-btn value="4day">
        4 Jours
        <v-icon>mdi-view-week-outline</v-icon>
      </v-btn>
      <v-btn value="week">
        Semaine
        <v-icon>mdi-view-module-outline</v-icon>
      </v-btn>
      <v-btn value="month">
        Mois
        <v-icon>mdi-view-comfy-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import moment from 'moment';

export default {
    data(){
      return {
        isMobile,
        moment,
        datePickerModel: false
      }
    },
    props: {
      loaded: {
        type: Boolean,
        required: true
      }
    },
    computed: {
        currentDate(){
          let date = this.$store.getters.calendarValue ? moment(this.$store.getters.calendarValue) : moment();
          if(['week', '4day'].includes(this.$store.getters.calendarMode)){
            date = moment(date).locale('fr').startOf('week').format('DD/MM/YYYY') + ' - ' + moment(date).locale('fr').endOf('week').format('DD/MM/YYYY')
          } else if (this.$store.getters.calendarMode === 'month'){
              date = moment(date).locale('fr').format('MM/YYYY')
          } else {
            date = moment(date).format('DD/MM/YYYY')
          }
          return date;
        },
        calendarValue: {
            get(){
                return this.$store.getters.calendarValue
            },
            set(val){
                this.$store.commit('setCalendarValue', val)
            }
        },
        calendarMode: {
          get(){
            return this.$store.getters.calendarMode
          },
          set(val){
            this.$store.commit('setCalendarMode', val);
          }
        },
        calendarEvents(){
            let events = []
            if(this.$route.name === 'Visites guidées' && this.loaded){
              if(this.$store.getters.events !== undefined && this.$store.getters.events.length){
                this.$store.getters.events.forEach(event => {
                    if(event){
                      if(!event.tour_id){
                        console.log('no tour:', event);
                      } else {
                        if(event.tour_id?.isNote){
                        events.push({
                                id: event._id,
                                name: event.name,
                                start: this.$store.getters.moment(event.date).format('YYYY-MM-DD'),
                                end: this.$store.getters.moment(event.endDate ? event.endDate : event.date).format('YYYY-MM-DD'),
                                color: event.tour_id.color,
                                freeTitle: event.freeTitle,
                                tour: event.tour_id
                            })
                        } else {
                            let pax = 0
                            let hasGroup = false
                            event.rates.forEach(rate => {
                                rate.pax && !event.cancelled ? pax += rate.pax : null
                                if(rate.isGroup !== undefined && rate.isGroup === true && rate.pax){
                                  hasGroup = true
                                }
                            })
                            let time = event.slot ? event.slot.replace('h',':') : '09:00'
                            let duration = [0, event.tour_id ? event.tour_id.duration : 45]
                            if(event.duration){
                              duration = [event.duration.hours, event.duration.minutes]
                            }
                            let start = this.$store.getters.moment(event.date).format('YYYY-MM-DD')
                            let endTime = this.$store.getters.moment(`${start} ${time}`)
                            endTime = endTime.add({hours: parseInt(duration[0], 10), minutes: parseInt(duration[1], 10)}).format('YYYY-MM-DD HH:mm')
                            let existingIndex = events.findIndex(evt => evt.tour._id === event.tour_id._id && evt.start === `${start} ${time}` && evt.isGroup === hasGroup)
  
                            let color = hasGroup ? event.tour_id.groupColor : event.tour_id.color;
  
                            if(event.noShow === true){
                              color = '#ffa468';
                            }
  
                            if(event.cancelled === true){
                              color = '#2b2b2b'
                            }
  
                            let noShow = event.noShow;
  
                            if(event.noShow === undefined){
                              noShow = false;
                            }
                            
                            if(existingIndex < 0) {
                                let evt = {
                                    name: hasGroup ? event.name : event.tour_id.frTitle,
                                    freeTitle: event?.freeTitle,
                                    start: `${start} ${time}`,
                                    end: endTime,
                                    color,
                                    pax,
                                    bookingsAmount: event.cancelled ? 0 : 1,
                                    paid: event.paid,
                                    confirmed: event.confirmed,
                                    noShow,
                                    source: event.source,
                                    category: event.title,
                                    id: event._id,
                                    locale: event.locale,
                                    cancelled: event.cancelled,
                                    tour: event.tour_id,
                                    isGroup: hasGroup,
                                };
                                if(event.tour_id.freeTitle){
                                  evt.freeTitle = event.freeTitle;
                                }
                                events.push(evt)
                            } else {
                                events[existingIndex].pax += pax
                                events[existingIndex].bookingsAmount += event.cancelled ? 0 : 1
                                let noShow = event.noShow
                                if(noShow === undefined){ noShow = false }
                                if(events[existingIndex].paid !== event.paid){
                                    events[existingIndex].partiallyPaid = true
                                }
                                if(events[existingIndex].source !== 'both' && events[existingIndex].source !== event.source){
                                    events[existingIndex].source = 'both'
                                }
                                if(events[existingIndex].confirmed !== event.confirmed){
                                    events[existingIndex].partiallyConfirmed = true
                                }
                                if(events[existingIndex].locale !== event.locale){
                                  events[existingIndex].locale = 'multi'
                                }
                                if(events[existingIndex].noShow !== noShow){
                                  events[existingIndex].partiallyNoShow = true
                                  events[existingIndex].color = hasGroup ? event.tour_id00.groupColor : event.tour_id.color
                                }
                                if(events[existingIndex].cancelled !== event.cancelled){
                                  events[existingIndex].partiallyCancelled = true
                                  events[existingIndex].color = hasGroup ? event.tour_id.groupColor : event.tour_id.color
                                }
                            }
                        }
                      }
                    }
                })
              }
            }
            return events
        }
    },
    methods: {
      prev(){
        this.$refs.calendar.prev()
      },
      next(){
        this.$refs.calendar.next()
      },
    }
}
</script>
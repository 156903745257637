<template>
    <v-dialog
        width="50%"
        class="overflow-y-hidden"
        v-model="dialog"
    >
        <v-card
            class="d-flex flex-column overflow-y-hidden"
            height="90vh"
        >
            <v-toolbar
                dark
                color="primary"
                height="60px"
                style="flex: 0"
            >
                <v-card-title>
                    <span v-if="selectedEvent">
                        {{selectedEvent.title}} | {{moment(selectedEvent.date).locale('fr').format('dddd D MMMM YYYY')}}
                    </span>
                    <span v-else-if="eventToCreate">
                        {{eventToCreate.title}}<span v-if="eventToCreate.date"> | {{ moment(eventToCreate.date).locale('fr').format('dddd D MMMM YYYY')}}</span>
                    </span>
                </v-card-title>
                <v-spacer />
                <v-icon
                    color="error"
                    v-if="selectedEvent"
                    @click="deleteRoom"
                >
                    mdi-delete
                </v-icon>
                <v-icon class="ml-4" @click="$store.commit('setRoomDialog', false)">
                    mdi-close
                </v-icon>
            </v-toolbar>
            <v-card-text class="flex overflow-y-auto">
                <v-row
                    v-if="selectedEvent || eventToCreate"
                    class="mt-4"
                >
                    <v-col cols="4">
                        <v-select
                            :menu-props="{offsetY: true}"
                            v-if="selectedEvent"
                            v-model="selectedEvent.title"
                            label="Salle"
                            :items="['Salle Normande', 'Salle de l\'Orangerie']"
                        />
                        <v-select
                            :menu-props="{offsetY: true}"
                            v-else-if="eventToCreate"
                            v-model="eventToCreate.title"
                            label="Salle"
                            :items="['Salle Normande', 'Salle de l\'Orangerie']"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-menu
                            v-if="selectedEvent"
                            offset-y
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="selectedEvent"
                                    v-model="selectedEventParsedDate"
                                    label="Date"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                first-day-of-week="1"
                                v-if="selectedEvent"
                                v-model="selectedEvent.date"
                            />
                        </v-menu>
                        <v-menu v-if="eventToCreate" offset-y>
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="eventToCreate"
                                    v-model="eventToCreateParsedDate"
                                    label="Date"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                v-if="eventToCreate"
                                v-model="eventToCreate.date"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-menu
                            bottom
                            offset-y
                            v-if="selectedEvent"
                            :close-on-content-click="false"
                            v-model="startTimePicker"
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-model="selectedEvent.start"
                                    label="Heure de début"
                                    v-on="on"
                                    v-bind="attrs"
                                    @change="val => parseTime(val, 'start')"
                                />
                            </template>
                            <v-time-picker
                                v-model="parsedSelectedStart"
                                format="24hr"
                                @click:minute="startTimePicker = false"
                            />
                        </v-menu>
                        <v-menu
                            bottom
                            offset-y
                            v-if="eventToCreate"
                            :close-on-content-click="false"
                            v-model="startTimePicker"
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-model="eventToCreate.start"
                                    label="Heure de début"
                                    v-on="on"
                                    v-bind="attrs"
                                    @change="val => parseTime(val, 'start')"
                                />
                            </template>
                            <v-time-picker
                                v-model="parsedCreateStart"
                                format="24hr"
                                @click:minute="startTimePicker = false"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-menu
                            bottom
                            offset-y
                            v-if="selectedEvent"
                            :close-on-content-click="false"
                            v-model="endTimePicker"
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-model="selectedEvent.end"
                                    label="Heure de fin"
                                    v-on="on"
                                    v-bind="attrs"
                                    @change="val => parseTime(val, 'end')"
                                />
                            </template>
                            <v-time-picker
                                v-model="parsedSelectedEnd"
                                format="24hr"
                                @click:minute="endTimePicker = false"
                            />
                        </v-menu>
                        <v-menu
                            bottom
                            offset-y
                            v-if="eventToCreate"
                            :close-on-content-click="false"
                            v-model="endTimePicker"
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-model="eventToCreate.end"
                                    label="Heure de fin"
                                    v-on="on"
                                    v-bind="attrs"
                                    @change="val => parseTime(val, 'end')"
                                />
                            </template>
                            <v-time-picker
                                v-model="parsedCreateEnd"
                                format="24hr"
                                @click:minute="endTimePicker = false"
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <div v-if="selectedEvent">
                    <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                        <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                        <div class="ml-2">Détails</div>
                        <v-spacer />
                        <v-tooltip bottom>
                            <template #activator="tooltip">
                                <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                    <template #activator="menu">
                                        <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="selectedEvent.cancelled ? 'error' : selectedEvent.isOption === true  ? 'yellow' : '#999'">
                                            <v-icon v-if="selectedEvent.cancelled === true">mdi-cancel</v-icon>
                                            <v-icon v-else-if="selectedEvent.isOption === true">mdi-account-question-outline</v-icon>
                                            <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-checkbox label="Option" v-model="selectedEvent.isOption"></v-checkbox>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-checkbox label="Annulé" v-model="selectedEvent.cancelled"></v-checkbox>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <span>Statut</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{on, attrs}">
                                <v-btn
                                    @click="selectedEvent.locale === 'fr' ? selectedEvent.locale = 'en' : selectedEvent.locale = 'fr'"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                >
                                    <v-avatar height="24px" width="24px">
                                        <img :src="require(`@/assets/flag-${selectedEvent.locale}.png`)" />
                                    </v-avatar>
                                </v-btn>
                            </template>
                            Changer la langue
                        </v-tooltip>
                    </v-toolbar>
                    <v-card class="mx-4 pt-4" style="margin-top: -15px">
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Nom"
                                        v-model="selectedEvent.name"
                                    />
                                </v-col>
                                <v-col cols="6" class="d-flex align-center justify-end">
                                    <v-switch color="success" label="Payé" v-model="selectedEvent.paid" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.email"
                                        label="Email"
                                    />
                                    <v-text-field
                                        v-model="selectedEvent.phone"
                                        label="Téléphone"
                                    />
                                    <v-text-field type="number" min="0" v-model.number="selectedEvent.pax" label="Pax" />
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        v-model="selectedEvent.notes"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div v-else-if="eventToCreate">
                    <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                        <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                        <div class="ml-2">Détails</div>
                        <v-spacer />
                        <v-tooltip bottom>
                            <template #activator="tooltip">
                                <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                    <template #activator="menu">
                                        <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="eventToCreate.cancelled ? 'error' : eventToCreate.isOption === true  ? 'yellow' : '#999'">
                                            <v-icon v-if="eventToCreate.isOption === true">mdi-account-question-outline</v-icon>
                                            <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-checkbox label="Option" v-model="eventToCreate.isOption"></v-checkbox>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <span>Statut</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{on, attrs}">
                                <v-btn
                                    @click="eventToCreate.locale === 'fr' ? eventToCreate.locale = 'en' : eventToCreate.locale = 'fr'"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                >
                                    <v-avatar height="24px" width="24px">
                                        <img :src="require(`@/assets/flag-${eventToCreate.locale}.png`)" />
                                    </v-avatar>
                                </v-btn>
                            </template>
                            Changer la langue
                        </v-tooltip>
                    </v-toolbar>
                    <v-card class="mx-4 pt-4" style="margin-top: -15px">
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Nom"
                                        v-model="eventToCreate.name"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6" class="d-flex align-center justify-end">
                                    <v-switch hide-details color="success" label="Payé" v-model="eventToCreate.paid" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.email"
                                        label="Email"
                                    />
                                    <v-text-field
                                        v-model="eventToCreate.phone"
                                        label="Téléphone"
                                    />
                                    <v-text-field type="number" min="0" v-model.number="eventToCreate.pax" label="Pax" />
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        outlined
                                        rows="7"
                                        v-model="eventToCreate.notes"
                                        label="Notes"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions
                style="height: 60px"
                class="d-flex justify-end"
            >
                <v-btn
                    color="error"
                    @click="dialog = false"
                >
                    Annuler
                </v-btn>
                <v-btn
                    color="success"
                    @click="selectedEvent ? $store.dispatch('updateRoom') : $store.dispatch('createRoom')"
                    class="ml-4"
                >
                    Sauvegarder
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            customToolbar: [ 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ],
            startTimePicker: false,
            endTimePicker: false,
        }
    },
    computed: {
        eventToCreateParsedDate(){
            return this.moment(this.eventToCreate?.date).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedDate(){
            return this.moment(this.selectedEvent?.date).locale('fr').format('D MMM YYYY')
        },
        parsedSelectedStart: {
            get(){
                if(this.selectedEvent){
                    return this.selectedEvent.start.replace('h', ':')
                }
                return '09:00'
            },
            set(val){
                this.selectedEvent.start = val.replace(':', 'h')
            }
        },
        parsedSelectedEnd: {
            get(){
                if(this.selectedEvent){
                    return this.selectedEvent.end.replace('h', ':')
                }
                return '14:00'
            },
            set(val){
                this.selectedEvent.end = val.replace(':', 'h')
            }
        },
        parsedCreateStart: {
            get(){
                if(this.eventToCreate){
                    return this.eventToCreate.start.replace('h', ':')
                }
                return '09:00'
            },
            set(val){
                this.eventToCreate.start = val.replace(':', 'h')
            }
        },
        parsedCreateEnd: {
            get(){
                if(this.eventToCreate){
                    return this.eventToCreate.end.replace('h', ':')
                }
                return '14:00'
            },
            set(val){
                this.eventToCreate.end = val.replace(':', 'h')
            }
        },
        dialog: {
            get(){
                return this.$store.getters.roomDialog
            },
            set(val){
                this.$store.commit('setRoomDialog', val)
            }
        },
        selectedEvent: {
            get(){
                return this.$store.getters.selectedEvent
            },
            set(val){
                this.$store.commit('setSelectedEvent', val)
            }
        },
        eventToCreate: {
            get(){
                return this.$store.getters.eventToCreate
            },
            set(val){
                this.$store.commit('setEventToCreate', val)
            }
        },
        moment(){
            return this.$store.getters.moment
        }
    },
    methods: {
        parseTime(value, type){
            let val = value.replace(/\D/g,'')
            if(val.length < 4){
                val += '0'
                this.parseTime(val, type)
                return
            }
            if(val.length > 4){
                val = val.slice(0, 4)
            }
            let hours = val.slice(0,2)
            let minutes = val.slice(2)
            if(parseInt(hours, 10) < 9){
                hours = '09';
            }
            if(parseInt(hours, 10) > 18){
                hours = '18';
            }
            if(parseInt(minutes, 10) < 0){
                minutes = '00';
            }
            if(parseInt(minutes, 10) > 59){
                minutes = '59';
            }
            let slot = hours+'h'+minutes
            
            this.eventToCreate ? this.eventToCreate[type === 'start' ? 'start' : 'end'] = slot : this.selectedEvent[type === 'start' ? 'start' : 'end'] = slot 
        },
        deleteRoom(){
            let confirm = window.confirm('Êtes-vous sûr(e) de vouloir supprimer cette réservation ?')
            if(confirm){
                this.$store.dispatch('deleteRoom', this.selectedEvent)
            }
        }
    }
}
</script>
<template>
    <div id="side-column">
      <div>
        <v-btn
            rounded
            class="pa-6"
            @click="$store.dispatch('openRoomDialog', {event: null, eventToCreate: {name: 'Chambre Tour'}})"
        >
            <v-icon left color="primary" class="mr-4" large>
            mdi-plus
            </v-icon>
            Créer
            <v-icon right>
            mdi-chevron-down
            </v-icon>
        </v-btn>
        <v-date-picker
          v-model="calendarValue"
          no-title
          class="mt-3 pa-0 mx-0"
          id="side-date-picker"
          width="100%"
          locale="fr"
          color="primary"
          first-day-of-week="1"
          :events="$store.getters.events.map(event => event ? $store.getters.moment(event.date).format('YYYY-MM-DD') : false)"
          event-color="primary"
        />
      </div>
      <div>
        <small>Mise à jour: {{$store.getters.updateLog[0].date}}</small>
        <v-icon @click="logDialog = true" style="padding-bottom: 2px" class="cursor-pointer ml-1" small>mdi-information-outline</v-icon>
      </div>
      <v-dialog v-model="logDialog" width="40%">
        <v-card>
          <v-card-title>
            Mises à jour
          </v-card-title>
          <v-card-text>
            <template v-for="(update, index) in $store.getters.updateLog">
              <div :key="index" :class="index > 0 ? 'mt-3' : ''">
                <h3>{{update.date}}</h3>
                <ul class="mt-2">
                  <li v-for="(updateItem, idx) in update.updates" :key="idx">
                    {{updateItem}}
                  </li>
                </ul>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
export default {
    data(){
      return {
        logDialog: false
      }
    },
    computed: {
        calendarValue: {
            get(){
                return this.$store.getters.calendarValue
            },
            set(val){
                this.$store.commit('setCalendarValue', val)
            }
        },
    }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import updateLog from '@/resources/updateLog'
import moment from 'moment'
import _axios from '../plugins/axios'


let tours = [];

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    calendarValue: '',
    calendarMode: 'week',
    bookingEditDialog: false,
    bookingCreateDialog: false,
    roomDialog: false,
    hotelRoomDialog: false,
    selectedEvent: null,
    eventToCreate: null,
    events: [],
    updateLog,
    tours,
    axios: _axios,
    moment,
    changingTitle: false,
    changingDate: false,
    changingTime: false,
    deletingBooking: false,
    errors: [],
    dragging: false,
    ws: null
  },
  getters: {
    calendarValue(state){
      return state.calendarValue
    },
    calendarMode(state){
      return state.calendarMode
    },
    bookingEditDialog(state){
      return state.bookingEditDialog
    },
    bookingCreateDialog(state){
      return state.bookingCreateDialog
    },
    roomDialog(state){
      return state.roomDialog
    },
    hotelRoomDialog(state){
      return state.hotelRoomDialog
    },
    selectedEvent(state){
      return state.selectedEvent
    },
    eventToCreate(state){
      return state.eventToCreate
    },
    events(state){
      return state.events
    },
    updateLog(state){
      return state.updateLog
    },
    tours(state) {
      return state.tours
    },
    moment(state){
      return state.moment
    },
    changingTitle(state){
      return state.changingTitle
    },
    changingDate(state){
      return state.changingDate
    },
    changingTime(state){
      return state.changingTime
    },
    deletingBooking(state){
      return state.deletingBooking
    },
    errors(state){
      return state.errors
    },
    dragging(state){
      return state.dragging
    },
    ws(state){
      return state.ws
    }
  },
  mutations: {
    setAuthorization(state, value){
      state.axios.defaults.headers.common['Authorization'] = value;
    },
    setCalendarValue(state, value){
      state.calendarValue = value
    },
    setCalendarMode(state, value){
      state.calendarMode = value
    },
    setBookingEditDialog(state, value){
      state.bookingEditDialog = value
      if(!value){
        state.selectedEvent = null
        state.eventToCreate = null
        state.dragging = false
      }
    },
    setBookingCreateDialog(state, value){
      state.bookingCreateDialog = value
      if(!value){
        state.selectedEvent = null
        state.eventToCreate = null
        state.dragging = false
      }
    },
    setRoomDialog(state, value){
      state.roomDialog = value
      if(!value){
        state.selectedEvent = null
        state.eventToCreate = null
        state.dragging = false;
      }
    },
    setHotelRoomDialog(state, value){
      state.hotelRoomDialog = value
      if(!value){
        state.selectedEvent = null
        state.eventToCreate = null
        state.dragging = false;
      }
    },
    setSelectedEvent(state, {value, reverse}){
      if(value){
        if(value.slot){
          if(value.bookings.length){
            value.bookings.forEach(booking => {
              booking.tour_id = value.tour_id._id
              booking.category_id = value.tour_id.category
              booking.slot = value.slot
              booking.date = value.date
            })
          }
        }
      }
      state.selectedEvent = value
      if(reverse && value?.bookings.length){
        state.selectedEvent.bookings.reverse()
      }
    },
    setSelectedEventBooking(state, {value, index}){
      state.selectedEvent.bookings[index] = value
    },
    setEventToCreate(state, {value, reverse}){
      state.eventToCreate = value
      if(value && reverse){
          state.eventToCreate.bookings.reverse()
      }
    },
    setEventToCreateBooking(state, {value, index}){
      state.eventToCreate.bookings[index] = value
    },
    setEvents(state, value){
      state.events = value
    },
    setChangingTitle(state, value){
      state.changingTitle = value
    },
    setChangingDate(state, value){
      state.changingDate = value
    },
    setChangingTime(state, value){
      state.changingTime = value
    },
    setDeletingBooking(state, value){
      state.deletingBooking = value
    },
    resetErrors(state){
      state.errors = []
    },
    addError(state, error){
      state.errors.push(error)
    },
    setDragging(state, value){
      state.dragging = value
    },
    setTours(state, value){
      state.tours = value;
    },
    setWebSocket(state, value){
      state.ws = value;
    }
  },
  actions: {
    initWebSocket({state, commit}){
      let ws = new WebSocket(process.env.VUE_APP_WS_URL)
      ws.addEventListener('open', () => {
        ws.addEventListener('message', async (event) => {
          let message = await event.data.text();
          if(message === 'update'){
            commit('setEvents', [])
            state.axios.get('bookings').then(res => {
              if(res.data){
                commit('setEvents', res.data.result)
              }
            })
          }
        });
      });
      commit('setWebSocket', ws)
    },
    openHotelRoomDialog({state, commit}, {event, eventToCreate}){
      if (!state.roomDialog && !state.hotelRoomDialog && !state.bookingDialog && !state.dragging){
        commit('setSelectedEvent', {value: null, reverse: false});
        commit('setEventToCreate', {value: null, reverse: false});
        if(event){
          let selectedEvent = {...state.events.find(evt => evt._id === event.event.id)}
          selectedEvent.start = state.moment(selectedEvent.start).format('YYYY-MM-DD');
          commit('setSelectedEvent', {value: selectedEvent, reverse: false});
        } else if (eventToCreate){
          commit('setEventToCreate', {
            value: {
              title: eventToCreate.name ? eventToCreate.name : 'Chambre',
              start: eventToCreate.start,
              end: eventToCreate.end,
              name: '',
              email: '',
              phone: '',
              notes: '',
              paid: false,
              cancelled: false,
              pax: 0,
              isOption: false,
              locale: 'fr'
            },
            reverse: false
          })
        }
        commit('setHotelRoomDialog', true);
      } else {
        console.log('hotelRoomDialog: ', state.hotelRoomDialog, 'roomDialog: ', state.roomDialog, 'bookingDialog: ', state.bookingDialog, 'dragging: ', state.dragging)
      }
    },
    openRoomDialog({state, commit}, {event, eventToCreate}){
      if(!state.hotelRoomDialog && !state.roomDialog && !state.bookingDialog && !state.dragging){
        commit('setSelectedEvent', {value: null, reverse: false});
        commit('setEventToCreate', {value: null, reverse: false});
        if(event){
          let selectedEvent = {...state.events.find(evt => evt._id === event.event.id)}
          selectedEvent.date = state.moment(selectedEvent.date).format('YYYY-MM-DD')
          commit('setSelectedEvent', {value: selectedEvent, reverse: false})
        } else if (eventToCreate) {
          let start = '09h00';
          let end = '14h00';
          if(eventToCreate.start){
            let minutes = ''
            if(eventToCreate.start.minute < 15){
              minutes = '00'
            } else if(eventToCreate.start.minute < 30){
              minutes = '15'
            } else if(eventToCreate.start.minute < 45){
              minutes = '30'
            } else {
              minutes = '45'
            }
            start = `${eventToCreate.start.hour}h${minutes}`;
            end = `${eventToCreate.start.hour + 5}h${minutes}`;
          }
          commit('setEventToCreate', {
            value: {
              title: eventToCreate.name ? eventToCreate.name : 'Salle Normande',
              date: eventToCreate.date ? eventToCreate.date : state.moment().format('YYYY-MM-DD'), 
              start,
              end,
              name: '',
              email: '',
              phone: '',
              notes: '',
              paid: false,
              cancelled: false,
              pax: 0,
              isOption: false,
              locale: 'fr'
            },
            reverse: false
          })
        }
        commit('setRoomDialog', true)
      } else {
        console.log('roomDialog: ', state.roomDialog, 'bookingDialog: ', state.bookingDialog, 'dragging: ', state.dragging)
      }
    },
    openBookingCreateDialog({state, commit}, {date = null, time = null, tour}){
      if(!state.bookingCreateDialog && !state.bookingEditDialog && !state.roomDialog && !state.hotelRoomDialog && !state.dragging){
        commit('setSelectedEvent', {value: null, reverse: false});
        commit('setEventToCreate', {value: null, reverse: false});

        let slot = `${tour.slots[0].hours < 10 ? '0' + tour.slots[0].hours : tour.slots[0].hours }h${tour.slots[0].minutes < 10 ? '0' + tour.slots[0].minutes : tour.slots[0].minutes}`;
        
        if(time){
          let minutes = ''
          if(time.minute < 15){
            minutes = '00'
          } else if(time.minute < 30){
            minutes = '15'
          } else if(time.minute < 45){
            minutes = '30'
          } else {
            minutes = '45'
          }
          slot = `${time.hour}h${minutes}`
        }

        let eventToCreate = { 
          date: state.moment(date ? date : undefined).format('YYYY-MM-DD'),
          endDate: tour.isNote ? moment(date ? date : undefined).format('YYYY-MM-DD') : null,
          slot,
          tour,
          bookings: [],
          notes: '',
          freeTitle: ''
        };

        if(tour.freeTitle){
          eventToCreate.duration = {hours: 1, minutes: 0};
        }

        commit('setEventToCreate', {
          value: eventToCreate, 
          reverse: false
        })
        commit('setBookingCreateDialog', true)
      }
    },
    openBookingEditDialog({state, commit}, {event = null}){
      if(!state.bookingEditDialog && !state.bookingCreateDialog && !state.roomDialog && !state.dragging){
        commit('setSelectedEvent', {value: null, reverse: false});
        commit('setEventToCreate', {value: null, reverse: false});
          if(!event.event.tour.isNote){
            let selectedEvent = {...state.events.find(evt => evt._id === event.event.id)}
            selectedEvent.date = state.moment(selectedEvent.date).format('YYYY-MM-DD')
              selectedEvent.bookings = []
              state.events.forEach(evt => {
                let evtIsGroup = false;
                evt.rates.forEach(rate => {
                  if(rate.pax && rate.isGroup){
                    evtIsGroup = true;
                  }
                })
                if(evt.tour_id?._id === selectedEvent.tour_id._id && evt.slot === selectedEvent.slot && state.moment(evt.date).format('YYYY-MM-DD') === selectedEvent.date && evtIsGroup === event.event.isGroup){
                  selectedEvent.bookings.push({...evt})
                }
              })
              if(event.event.tour.freeTitle && selectedEvent.duration === undefined){
                selectedEvent.duration = {hours: 1, minutes: 0}
              }
              commit('setSelectedEvent', {value: selectedEvent, reverse: false})       
          } else {
              let selectedEvent = {...state.events.find(evt => evt._id === event.event.id)}
              selectedEvent.date = state.moment(selectedEvent.date).format('YYYY-MM-DD')
              commit('setSelectedEvent', {value: selectedEvent, reverse: false})
          }
        commit('setBookingEditDialog', true)
      }
    },
    changeTitle({state, commit}, booking){
      commit('setChangingTitle', true)
      let bkng = {...booking}
      bkng.category_id = bkng.tour_id.category;
      state.axios.put(`bookings/${booking._id}`, bkng)
        .then(res => {
          const bookingIndex = state.events.findIndex(bk => bk._id === bkng._id)
          let events = [...state.events]
          events.splice(bookingIndex, 1)
          res.data.result.tour_id = state.tours.find(tour => tour._id === bkng.tour_id._id);
          events.push(res.data.result)
          commit('setEvents', events)
          commit('setBookingEditDialog', false)
          state.ws.send('update');
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit('setChangingTitle', false)
        })
    },
    changeDate({state, commit}, booking){
      commit('setChangingDate', true)
      state.axios.put(`bookings/${booking._id}`, booking)
        .then(res => {
          const bookingIndex = state.events.findIndex(bkng => bkng._id === booking._id)
          let events = [...state.events]
          events.splice(bookingIndex, 1)
          events.push(res.data.result)
          commit('setEvents', events)
          commit('setBookingEditDialog', false)
          state.ws.send('update');
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit('setChangingDate', false)
        })
    },
    changeTime({state, commit}, booking){
      commit('setChangingTime', true)
      state.axios.put(`bookings/${booking._id}`, booking)
        .then(res => {
          const bookingIndex = state.events.findIndex(bkng => bkng._id === booking._id)
          let events = [...state.events]
          events.splice(bookingIndex, 1)
          events.push(res.data.result)
          commit('setEvents', events)
          commit('setBookingEditDialog', false)
          state.ws.send('update');
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit('setChangingTime', false)
        })
    },
    deleteRoom({state, commit}){
      commit('setDeletingBooking', true)
      state.axios.delete(`hotel-rooms/${state.selectedEvent._id}`)
      .then(() => {
        const bookingIndex = state.events.findIndex(bkng => bkng._id === state.selectedEvent._id)
        let events = [...state.events]
        events.splice(bookingIndex, 1)
        commit('setEvents', events)
        commit('setHotelRoomDialog', false)
        commit('setDeletingBooking', false)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteNote({state, commit}){
      commit('setDeletingBooking', true)
      state.axios.delete(`bookings/${state.selectedEvent._id}`)
      .then(() => {
        const bookingIndex = state.events.findIndex(bkng => bkng._id === state.selectedEvent._id)
        let events = [...state.events]
        events.splice(bookingIndex, 1)
        commit('setEvents', events)
        commit('setBookingEditDialog', false)
        commit('setDeletingBooking', false)
        state.ws.send('update');
      }).catch(err => {
        console.log(err)
      })
    },
    deleteBooking({state, commit}, booking){
      if(!booking._id){
        let eventBookingIndex = state.eventToCreate
          ? state.eventToCreate.bookings.findIndex(bkng => bkng === booking)
          : state.selectedEvent.bookings.findIndex(bkng => bkng === booking)
        let event = state.eventToCreate ? {...state.eventToCreate} : {...state.selectedEvent}
        event.bookings.splice(eventBookingIndex, 1)
        commit(state.eventToCreate ? 'setEventToCreate' : 'setSelectedEvent', {value: event, reverse: false})
        return
      }
      const eventBookingIndex = state.eventToCreate 
        ? state.eventToCreate.bookings.findIndex(bkng => bkng === booking)
        : state.selectedEvent.bookings.findIndex(bkng => bkng._id === booking._id)
      commit('setDeletingBooking', true)
      state.axios.delete(`bookings/${booking._id}`, booking)
        .then(() => {
          const bookingIndex = state.events.findIndex(bkng => bkng._id === booking._id)
          let event = state.eventToCreate ? {...state.eventToCreate} : {...state.selectedEvent}
          let events = [...state.events]
          event.bookings.splice(eventBookingIndex, 1)
          events.splice(bookingIndex, 1)
          commit('setSelectedEvent', {value: event, reverse: false})
          commit('setEvents', events)
          state.ws.send('update');
          if(!event.bookings.length){
            commit('setBookingEditDialog', false)
          }
          commit('setDeletingBooking', false)
        })
        .catch(err => { console.log(err) })
    },
    updateHotelRoom({state, commit}){
      commit('resetErrors');
      state.axios.put(`hotel-rooms/${state.selectedEvent._id}`, state.selectedEvent)
        .then(res => {
          let updatedBooking = res.data.result;
          let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
          let events = [...state.events];
          events.splice(formerBookingIndex, 1);
          events.push(updatedBooking)
          commit('setEvents', events);
          commit('setHotelRoomDialog', false)
        }).catch(err => console.log(err?.msg));
    },
    updateRoom({state, commit}){
      commit('resetErrors')
      state.axios.put(`rooms/${state.selectedEvent._id}`, state.selectedEvent)
        .then(res => {
          let updatedBooking = res.data.result
          let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
          let events = [...state.events]
          events.splice(formerBookingIndex, 1)
          events.push(updatedBooking)
          commit('setEvents', events)
          commit('setRoomDialog', false)
        }).catch(err => console.log(err?.msg))
    },
    updateEvent({state, commit}){
      commit('resetErrors')
      if(state.selectedEvent && (state.selectedEvent.tour_id.isNote || state.selectedEvent.tour_id.freeTitle)){
        state.axios.put(`bookings/${state.selectedEvent._id}`, state.selectedEvent)
          .then((res) => {
            let updatedBooking = res.data.result
            updatedBooking.tour_id = state.tours.find(tour => tour._id === updatedBooking.tour_id);
            let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
            let events = [...state.events]
            events.splice(formerBookingIndex, 1)
            events.push(updatedBooking)
            commit('setEvents', events)
            commit('setBookingEditDialog', false)
            state.ws.send('update');
          }).catch(err => console.log(err?.msg))
      } else {
        let sent = 0
        state.selectedEvent.bookings.forEach(booking => {
          booking.date = state.selectedEvent.date
          booking.slot = state.selectedEvent.slot 
          if(booking._id){
            state.axios.put(`bookings${booking._id ? '/'+booking._id : ''}`, booking)
              .then(res => {
                let updatedBooking = res.data.result
                updatedBooking.tour_id = state.tours.find(tour => tour._id === updatedBooking.tour_id);
                let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
                let events = [...state.events]
                events[formerBookingIndex] = updatedBooking
                commit('setEvents', events)
                sent+=1
              })
              .catch(e => console.log(e?.msg))
              .finally(() => {
                if(sent === state.selectedEvent.bookings.length){
                  commit('setBookingEditDialog', false)
                  state.ws.send('update');
                }
              })
          } else {
            state.axios.post(`bookings`, booking)
              .then(res => {
                let events = [...state.events]
                res.data.booking.tour_id = state.tours.find(tour => tour._id === res.data.booking.tour_id);
                events.push(res.data.booking)
                commit('setEvents', events)
                sent+=1
              })
              .catch(e => console.log(e?.msg))
              .finally(() => {
                if(sent === state.selectedEvent.bookings.length){
                  commit('setBookingEditDialog', false)
                  state.ws.send('update');
                }
              })
          }
        })
      }
    },
    createHotelRoom({state, commit}){
      commit('resetErrors');
      state.axios.post('hotel-rooms', state.eventToCreate)
      .then((res) => {
        let events = [...state.events];
        events.push(res.data.hotelRoom)
        commit('setEvents', events);
        commit('setHotelRoomDialog', false);
      })
    },
    createRoom({state, commit}){
      commit('resetErrors')
      state.axios.post('rooms', state.eventToCreate)
      .then((res) => {
        let events = [...state.events]
        events.push(res.data.room)
        commit('setEvents', events)
        commit('setRoomDialog', false)
      })
      .catch(e => console.log(e?.msg))
    },
    createEvent({state, commit}){
      commit('resetErrors')
      if(state.eventToCreate.tour.isNote){
        state.axios.post('bookings', {
          title: state.eventToCreate.tour.frTitle,
          date: state.eventToCreate.date,
          endDate: state.eventToCreate.endDate,
          notes: state.eventToCreate.notes,
          freeTitle: state.eventToCreate.freeTitle,
          tour_id: state.eventToCreate.tour._id,
          category_id: state.eventToCreate.tour.category._id,
        }).then(res => {
          let events = [...state.events]
          events.push(res.data.booking)
          commit('setEvents', events)
          commit('setBookingCreateDialog', false)
          state.ws.send('update');
        }).catch(e => console.log(e?.msg))
      } else if (state.eventToCreate.tour.freeTitle){
        state.axios.post('bookings', {
          title: state.eventToCreate.tour.frTitle,
          date: state.eventToCreate.date,
          slot: state.eventToCreate.slot,
          notes: state.eventToCreate.notes,
          freeTitle: state.eventToCreate.freeTitle,
          tour_id: state.eventToCreate.tour._id,
          category_id: state.eventToCreate.tour.category._id,
          duration: state.eventToCreate.duration
        }).then(res => {
          let events = [...state.events]
          events.push(res.data.booking)
          commit('setEvents', events)
          commit('setBookingCreateDialog', false)
          state.ws.send('update');
        }).catch(e => console.log(e?.msg))
      } else {
        let sent = 0
        state.eventToCreate.bookings.forEach(booking => {
          state.axios.post('bookings', booking)
            .then((res) => {
              let events = [...state.events]
              events.push(res.data.booking)
              commit('setEvents', events)
              sent+=1
            })
            .catch(e => console.log(e?.msg))
            .finally(() => {
              if(sent === state.eventToCreate.bookings.length){
                commit('setBookingCreateDialog', false)
                state.ws.send('update');
              }
            })
        })
      }
    },
    // eslint-disable-next-line
    async confirmHotelBooking({state, commit}, bookingID){
      return state.axios.get(`hotel-rooms/confirm/${bookingID}`)
        .then(res => {
          let updatedBooking = res.data.result
          let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
          let events = [...state.events];
          events.splice(formerBookingIndex, 1)
          events.push(updatedBooking)
          commit('setEvents', events)
          state.ws.state('update');
        })
        .catch(err => console.log(err));
    },
    // eslint-disable-next-line
    async confirmBooking({state, commit}, bookingID){
      return state.axios.get(`bookings/confirm/${bookingID}`)
        .then(res => {
          let updatedBooking = res.data.result
          let formerBookingIndex = state.events.findIndex(bkng => bkng._id === updatedBooking._id)
          let events = [...state.events]
          events.splice(formerBookingIndex, 1)
          events.push(updatedBooking)
          commit('setEvents', events)
          state.ws.send('update');
        })
        .catch(err => console.log(err))
    },
    addBooking({state, commit}){
      let event = state.eventToCreate ? {...state.eventToCreate} : {...state.selectedEvent}
      let tour = state.eventToCreate ? state.eventToCreate.tour : state.selectedEvent.tour_id;
      event.bookings.push({
        title: tour.frTitle,
        rates: tour.rates.map(rate => {
          return {
            ...rate,
            pax: 0
          }
        }),
        email: null,
        phone: null,
        confirmed: true,
        paid: false,
        notes: null,
        name: null,
        source: 'local',
        date: event.date,
        slot: event.slot,
        locale: 'fr',
        tour_id: tour,
        category_id: tour.category
      })
      state.eventToCreate ? commit('setEventToCreate', {value: event, reverse: true}) : commit('setSelectedEvent', {value: event, reverse: true})
    },
  }
})

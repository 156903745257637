<template>
    <div id="side-column">
      <div>
        <v-menu bottom offset-y nudge-bottom="8">
          <template #activator="{on, attrs}">
            <v-btn
              v-on="on"
              v-bind="attrs"
              rounded
              class="pa-6"
            >
              <v-icon left color="primary" class="mr-4" large>
                mdi-plus
              </v-icon>
              Créer
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <template
              v-for="(tour, index) in $store.getters.tours"
            >
              <v-list-item
                :key="index"
                class="d-flex align-center"
                @click="$store.dispatch('openBookingCreateDialog', {tour})"
              >
                <div :style="`margin-right: 8px; background-color: ${tour.color}; height: 20px; width: 20px; border-radius: 50%;`"></div>
                <div>{{tour.frTitle}}</div>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-date-picker
          v-model="calendarValue"
          no-title
          class="mt-3 pa-0 mx-0"
          id="side-date-picker"
          width="100%"
          locale="fr"
          color="primary"
          first-day-of-week="1"
          :events="$store.getters.events.map(event => event ? $store.getters.moment(event.date).format('YYYY-MM-DD') : false)"
          event-color="primary"
        />
      </div>
      <div>
        <small>Mise à jour: {{$store.getters.updateLog[0].date}}</small>
        <v-icon @click="logDialog = true" style="padding-bottom: 2px" class="cursor-pointer ml-1" small>mdi-information-outline</v-icon>
      </div>
      <v-dialog v-model="logDialog" width="40%">
        <v-card>
          <v-card-title>
            Mises à jour
          </v-card-title>
          <v-card-text>
            <template v-for="(update, index) in $store.getters.updateLog">
              <div :key="index" :class="index > 0 ? 'mt-3' : ''">
                <h3>{{update.date}}</h3>
                <ul class="mt-2">
                  <li v-for="(updateItem, idx) in update.updates" :key="idx">
                    {{updateItem}}
                  </li>
                </ul>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
export default {
    data(){
      return {
        logDialog: false
      }
    },
    computed: {
        calendarValue: {
            get(){
                return this.$store.getters.calendarValue
            },
            set(val){
                this.$store.commit('setCalendarValue', val)
            }
        },
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.name !== 'Connexion')?_c('v-app-bar',{staticClass:"app-bar",attrs:{"app":"","dark":"","color":"#1e2f53","flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),(_vm.isMobile)?_c('span',[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]):_vm._e(),(!_vm.isMobile)?[_c('img',{staticClass:"ml-2",staticStyle:{"height":"90%"},attrs:{"src":require('@/assets/logo-lsf.svg')}}),_c('v-btn',{staticClass:"mx-6",attrs:{"outlined":""},on:{"click":_vm.goToday}},[_vm._v("Aujourd'hui")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"mx-4",attrs:{"icon":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('span',[_vm._v(_vm._s(_vm.currentDate))]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"150px","height":"100%"}},[_c('v-select',{attrs:{"dense":"","hide-details":"","menu-props":{offsetY: true},"outlined":"","items":[
          {
            text: 'Jour',
            value: 'day'
          },
          {
            text: 'Semaine',
            value: 'week'
          },
          {
            text: 'Mois',
            value: 'month'
          }
        ]},model:{value:(_vm.calendarMode),callback:function ($$v) {_vm.calendarMode=$$v},expression:"calendarMode"}})],1)]:[_c('img',{staticStyle:{"height":"90%"},attrs:{"src":require('@/assets/logo-footer.svg')}})]],2):_vm._e(),_c('v-navigation-drawer',{attrs:{"app":"","absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-app-bar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Menu ")]),_c('v-list',{staticClass:"menu-list"},[_c('v-list-item',[_c('router-link',{attrs:{"to":"/"}},[_c('b',[_vm._v("Visites guidées")])])],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/hotel-rooms"}},[_c('b',[_vm._v("Chambres")])])],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/rooms"}},[_c('b',[_vm._v("Salles de séminaires")])])],1)],1)],1),_c('v-main',[_c('router-view',{ref:"view"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
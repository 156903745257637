<template>
  <v-container id="booking-container" class="pa-0 overflow-y-hidden" fluid>
    <BookingsSideColumn v-if="!isMobile" class="d-none d-md-block" />
    <BookingsCalendar ref="calendarModule" :loaded="loaded" />
    <BookingsEditDialog v-if="!isMobile" />
    <BookingsEditDialogMobile v-if="isMobile" />
    <BookingsCreateDialog v-if="!isMobile" />
    <BookingsCreateDialogMobile v-if="isMobile" />
  </v-container>
</template>

<script>
import BookingsSideColumn from '@/components/BookingsSideColumn.vue'
import BookingsCalendar from '@/components/BookingsCalendar.vue'
import BookingsEditDialog from '@/components/BookingsEditDialog.vue'
import BookingsCreateDialog from '@/components/BookingsCreateDialog.vue'
import BookingsCreateDialogMobile from '@/components/BookingsCreateDialogMobile.vue'
import BookingsEditDialogMobile from '@/components/BookingsEditDialogMobile.vue'
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'BookingsView',
  components: {
    BookingsSideColumn,
    BookingsCalendar,
    BookingsEditDialog,
    BookingsCreateDialog,
    BookingsCreateDialogMobile,
    BookingsEditDialogMobile
  },
  data(){
    return {
      isMobile,
      connection: null,
      loaded: false
    }
  },
  created(){
    this.axios.get('tours').then(res => this.$store.commit('setTours', res.data.items));
  },  
  computed: {
    calendarValue(){
      return this.$store.getters.calendarValue
    }
  },
  watch: {
    calendarValue(newVal, oldVal){
      let moment = this.$store.getters.moment
      let oldValue = oldVal ? oldVal : moment()
      let newValue = newVal ? newVal : moment()
      if(moment(newValue).format('MM-YYYY') !== moment(oldValue).format('MM-YYYY')){
        this.axios.get(`bookings?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
        .then(res => {
          this.$store.commit('setEvents', res.data.result)
        })
      }
    },
    '$route'(){
        if(this.$route.name === 'Visites guidées'){
          this.getEvents();
        }
    }
  },
  mounted(){
    this.$store.commit('setCalendarMode', isMobile ? 'day': 'week');
    this.getEvents();
  },
  methods: {
    getEvents(){
      this.loaded = false;
      this.axios.get(`bookings?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
        .then(res => {
          this.loaded = true;
          if(res.data){
            this.$store.commit('setEvents', res.data.result)
          }
        })
    }
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import BookingsView from '../views/BookingsView.vue'
import RoomsView from '../views/RoomsView.vue'
import HotelRoomsView from '../views/HotelRoomsView.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Visites guidées',
    component: BookingsView
  },
  {
    path: '/rooms',
    name: 'Salles de séminaire',
    component: RoomsView
  },
  {
    path: '/hotel-rooms',
    name: 'Chambres',
    component: HotelRoomsView
  },
  {
    path: '/login',
    name: 'Connexion',
    component: LoginView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

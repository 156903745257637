<template>
  <div
    class="d-flex"
    style="height: 100%"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        class="primary d-flex flex-column login-left-side"
        :class="!isMobile && slide ? 'py-8 slide-left' : !isMobile ? 'py-8' : ''"
      >
        <div
          class="mx-auto d-none d-md-block"
          style="width: 60%"
        >
          <h2 class="text-h2 white--text">
            Agenda Tourisme
          </h2>
          <h5 class="text-h5 white--text mt-5">
            Visites & Salles de séminaire
          </h5>
        </div>
        <div
          style="position: relative;"
          class="flex d-flex align-center justify-center"
        >
          <div
            class="login-circle"
            :class="loggedIn ? 'rotate' : ''"
          />
        </div>
      </v-col>
      <v-col
        class="d-flex align-center justify-center py-8 login-right-side"
        :class="!isMobile && slide ? 'slide-right' : ''"
        cols="12"
        md="6"
      >
        <div style="width: 70%" :class="isMobile ? 'text-center' : ''">
          <h5 class="text-h4">
            Connexion
          </h5>
          <small>Bienvenue ! Connectez vous avec votre email ou votre nom d'utilisateur.</small>
          <v-form
            class="my-9"
            @submit="login"
          >
            <v-text-field
              v-model="username"
              outlined
              prepend-inner-icon="mdi-account"
              label="Login"
              placeholder="Nom d'utilisateur ou adresse email"
            />
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              prepend-inner-icon="mdi-lock"
              label="Mot de passe"
            >
                <template #append>
                    <v-icon
                        @mousedown="showPassword = true"
                        @mouseup="showPassword = false"
                        @mouseleave="showPassword = false"
                    >
                        mdi-eye
                    </v-icon>
                </template>
            </v-text-field>
            <!-- <div class="text-right">
              <router-link
                link
                to="/forgot-password"
              >
                J'ai oublié mon mot de passe
              </router-link>
            </div> -->
            <v-btn
              class="w-100 mt-9"
              style="width: 100%"
              color="secondary"
              @click="login"
            >
              CONNEXION
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
    data(){
        return {
            isMobile,
            username: null,
            password: null,
            showPassword: false,
            loggedIn: false,
            slide: false
        };
    },
    methods: {
        login(){
            this.axios.post('auth/login', {username: this.username, password: this.password}).then(res => {
                localStorage.setItem('LSF_BACKOFFICE_ACCESS_TOKEN', res.data.accessToken);
                localStorage.setItem('LSF_BACKOFFICE_REFRESH_TOKEN', res.data.refreshToken);
                this.axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                this.$store.commit('setAuthorization', `Bearer ${res.data.accessToken}`);
                this.loggedIn = true;
                window.setTimeout(() => {
                    this.slide = true;
                    window.setTimeout(() => {
                        this.$router.push('/')
                    }, 500)
                }, 1000)
            });
        }
    }
};
</script>
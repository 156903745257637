<template>
  <v-app>
    <v-app-bar
      class="app-bar"
      app
      dark
      color="#1e2f53"
      flat
      v-if="$route.name !== 'Connexion'"
    >
      <!-- Bouton ouverture menu -->
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <!-- Nom de la route dans la top bar uniquement sur mobile -->
      <span v-if="isMobile">
        {{ $route.name }}
      </span>
      <!-- Calendrier dans la top bar uniquement sur desktop -->
      <template v-if="!isMobile">
        <img class="ml-2" :src="require('@/assets/logo-lsf.svg')" style="height: 90%" />
        <v-btn class="mx-6" outlined @click="goToday">Aujourd'hui</v-btn>
        <v-btn icon @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn icon @click="next" class="mx-4"><v-icon>mdi-chevron-right</v-icon></v-btn>
        <span>{{currentDate}}</span> 
        <v-spacer />
        <div style="width: 150px; height: 100%" class="d-flex align-center">
          <v-select dense hide-details :menu-props="{offsetY: true}" v-model="calendarMode" outlined :items="[
            {
              text: 'Jour',
              value: 'day'
            },
            {
              text: 'Semaine',
              value: 'week'
            },
            {
              text: 'Mois',
              value: 'month'
            }
          ]"
          />
        </div>
      </template>
      <!-- Top bar Logo Mobile -->
      <template v-else>
        <img :src="require('@/assets/logo-footer.svg')" style="height: 90%" />
      </template>
    </v-app-bar>
    <!-- Drawer de navigation -->
    <v-navigation-drawer app absolute temporary v-model="drawer">
      <v-app-bar color="primary" class="white--text">
        Menu
      </v-app-bar>
      <v-list class="menu-list">
        <v-list-item>
          <router-link to="/">
            <b>Visites guidées</b>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/hotel-rooms">
            <b>Chambres</b>
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/rooms">
            <b>Salles de séminaires</b>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- Vues -->
    <v-main>
      <router-view ref="view" />
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment'
import { isMobile } from 'mobile-device-detect';
  /**
   * @function moment 
   * @bool isMobile 
   */
  export default {
    data(){
      return {
        moment,
        isMobile,
        drawer: false,
      }
    },
    created(){
      this.$store.dispatch('initWebSocket');
    },
    computed: {
      currentDate(){
        let date = this.$store.getters.calendarValue
        if(!date){
          date = this.moment().locale('fr').format('MMMM YYYY')
        } else {
          date = this.moment(date).locale('fr').format('MMMM YYYY')
        }
        return date.charAt(0).toUpperCase() + date.slice(1);
      },
      calendarMode: {
        get(){
          return this.$store.getters.calendarMode
        },
        set(val){
          this.$store.commit('setCalendarMode', val)
        }
      }
    },
    methods: {
      prev(){
        if(this.$route.name === 'Salles de séminaire') {
          this.$refs.view.$refs.roomsCalendarModule.$refs.calendar.prev()
        } else if(this.$route.name === 'Chambres') {
          this.$refs.view.$refs.hotelRoomsCalendarModule.$refs.calendar.prev()
        } else {
          this.$refs.view.$refs.calendarModule.$refs.calendar.prev()
        }
      },
      next(){
        if(this.$route.name === 'Salles de séminaire'){
          this.$refs.view.$refs.roomsCalendarModule.$refs.calendar.next()
        } else if (this.$route.name === 'Chambres'){
          this.$refs.view.$refs.hotelRoomsCalendarModule.$refs.calendar.next()
        } else {
          this.$refs.view.$refs.calendarModule.$refs.calendar.next()
        }
      },
      goToday(){
        this.$store.commit('setCalendarValue', '')
      }
    }
  }
</script>

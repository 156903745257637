<template>
    <v-container id="booking-container" class="pa-0 overflow-y-hidden" fluid>
        <HotelRoomsSideColumn v-if="!isMobile" class="d-none d-md-block" />
        <HotelRoomsCalendar ref="hotelRoomsCalendarModule" />
        <HotelRoomsDialog v-if="!isMobile" />
        <HotelRoomsDialogMobile v-if="isMobile" />
  </v-container>
</template>

<script>
import HotelRoomsSideColumn from '@/components/HotelRoomsSideColumn.vue';
import HotelRoomsCalendar from '@/components/HotelRoomsCalendar.vue';
import HotelRoomsDialog from '@/components/HotelRoomsDialog.vue';
import HotelRoomsDialogMobile from '@/components/HotelRoomsDialogMobile.vue';
import { isMobile } from 'mobile-device-detect';

export default {
    data(){
        return {
            isMobile
        }
    },
    components: {
        HotelRoomsCalendar,
        HotelRoomsSideColumn,
        HotelRoomsDialog,
        HotelRoomsDialogMobile
    },
    computed: {
        calendarValue(){
            return this.$store.getters.calendarValue
        }
    },
    watch: {
        calendarValue(newVal, oldVal){
            let moment = this.$store.getters.moment
            let oldValue = oldVal ? oldVal : moment()
            let newValue = newVal ? newVal : moment()
            if(moment(newValue).format('MM-YYYY') !== moment(oldValue).format('MM-YYYY')){
                this.axios.get(`hotel-rooms?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
                .then(res => {
                    this.$store.commit('setEvents', res.data.result)
                })
            }
        }
    },
    mounted(){
        this.$store.commit('setCalendarMode', 'month');
        this.axios.get(`hotel-rooms?date=${this.calendarValue ? this.calendarValue : this.$store.getters.moment().format('YYYY-MM-DD')}`)
        .then(res => {
            if(res.data){
                this.$store.commit('setEvents', res.data.result)
            }
        })
    },
}
</script>
<template>
    <div>
        <v-toolbar dense dark class="mt-4 event-bookings-header" style="z-index: 10" elevation="0" color="primary" :key="'h-'+index">
            <div class="d-flex align-center">
                <v-icon v-if="booking.source === 'eshop'">mdi-web</v-icon>
                <img v-else :src="require('@/assets/logo-footer.svg')" style="height: 24px; fill: white" />
                <div class="ml-2" style="flex: 0">{{booking.source === 'eshop' ? 'E-Shop' : 'Local'}}</div>
            </div>
            <v-spacer v-if="!isMobile" />
            <div :style="isMobile ? 'margin-left: -12px;' : ''">
                <v-tooltip v-if="type !== 'create'" bottom>
                    <template #activator="tooltip">
                        <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                            <template #activator="menu">
                                <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="booking.noShow || booking.cancelled ? 'error' : booking.isOption === true  ? 'yellow' : '#999'">
                                    <v-icon v-if="booking.cancelled === true">mdi-cancel</v-icon>
                                    <v-icon v-else-if="booking.noShow === true">mdi-emoticon-cry-outline</v-icon>
                                    <v-icon v-else-if="booking.isOption === true">mdi-account-question-outline</v-icon>
                                    <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-checkbox label="Option" v-model="booking.isOption"></v-checkbox>
                                </v-list-item>
                                <v-list-item>
                                    <v-checkbox label="No Show" v-model="booking.noShow"></v-checkbox>
                                </v-list-item>
                                <v-list-item>
                                    <v-checkbox label="Annulé" v-model="booking.cancelled"></v-checkbox>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <span>Statut</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template #activator="{on, attrs}">
                        <v-menu offset-y left>
                            <template #activator="languageActivator">
                                <v-btn
                                    v-bind="languageActivator.attrs"
                                    v-on="languageActivator.on"
                                    icon
                                >
                                    <v-avatar v-on="on" v-bind="attrs" height="24px" width="24px">
                                        <img :src="require(`@/assets/flag-${booking.locale}.png`)" />
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-radio-group v-model="booking.locale">
                                        <v-radio label="Français" value="fr" />
                                        <v-radio label="Anglais" value="en" />
                                        <v-radio label="Allemand" value="de" />
                                    </v-radio-group>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    Changer la langue
                </v-tooltip>
                <v-tooltip bottom>
                    <template #activator="titleTooltip">
                        <v-menu v-if="type !== 'create'" offset-y :close-on-content-click="false">
                            <template #activator="{on, attrs}">
                                <v-btn v-on="on" v-bind="attrs" icon>
                                    <v-icon v-on="titleTooltip.on" v-bind="titleTooltip.attrs">mdi-swap-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <div class="d-flex flex-column" style="background-color: white">
                                <v-select :menu-props="{offsetY: true}" class="pa-2" v-model="booking.tour_id" solo :items="$store.getters.tours.map(tour => { return {text: tour.frTitle, value: tour}})" label="Visite" />
                                <v-btn @click="$store.dispatch('changeTitle', booking)" :loading="$store.getters.changingTitle" color="success">Mettre à jour</v-btn>
                            </div>
                        </v-menu>
                    </template>
                    Changer de visite
                </v-tooltip>
                <v-menu v-if="type !== 'create'" offset-y :close-on-content-click="false">
                    <template #activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon>
                            <v-icon>mdi-calendar-refresh</v-icon>
                        </v-btn>
                    </template>
                    <div class="d-flex flex-column">
                        <v-date-picker locale="fr" first-day-of-week="1" no-title v-model="booking.date" />
                        <v-btn @click="$store.dispatch('changeDate', booking)" :loading="$store.getters.changingDate" color="success">Mettre à jour</v-btn>
                    </div>
                </v-menu>
                <v-menu
                    v-if="type !== 'create'"
                    offset-y
                    :close-on-content-click="false"
                    v-model="timePicker"
                >
                    <template #activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon>
                            <v-icon>mdi-clock-edit</v-icon>
                        </v-btn>
                    </template>
                    <div class="d-flex flex-column">
                        <v-time-picker
                            v-model="parsedSlot"
                            format="24hr"
                            :allowed-hours="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18]"
                            :allowed-minutes="[0, 15, 30, 45]"
                    />
                        <v-btn @click="changeTime" :loading="$store.getters.changingTime" color="success">Mettre à jour</v-btn>
                    </div>
                </v-menu>
                <v-btn icon>
                    <v-icon color="error" @click="deleteBooking" :loading="$store.getters.deletingBooking">mdi-delete</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card class="mx-4 pt-4" style="margin-top: -15px" :key="index">
            <v-card-text>
                <v-row>
                    <v-col cols="8" md="6">
                        <v-text-field v-model="booking.name" label="Nom" hide-details />
                    </v-col>
                    <v-col cols="4" md="6" class="d-flex align-center justify-end">
                        <v-switch color="success" label="Payé" v-model="booking.paid" hide-details />
                        <v-btn @click="!booking.confirmed ? confirmBooking(booking) : null" small class="ml-4" v-if="booking.source === 'eshop'" :color="booking.confirmed ? 'success' : 'error'">
                            <v-progress-circular v-if="confirming === booking._id" indeterminate size="20" />
                            <span v-else-if="booking.confirmed">Confirmé</span>
                            <span v-else>Non confirmé</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="booking.email" label="Email" />
                        <v-text-field v-model="booking.phone" label="Téléphone" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-textarea outlined rows="4" v-model="booking.notes" label="Commentaire" />
                    </v-col>
                </v-row>
                <v-divider class="my-2" />
                <h4 class="my-4">Participants</h4>
                <v-row>
                    <v-col cols="6" md="3" v-for="(rate, idx) in booking.rates" :key="idx">
                        <v-text-field type="number" min="0" v-model.number="rate.pax" :label="rate.frLabel ? rate.frLabel : rate.label" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
    props: {
        type: String,
        index: Number,
        defaultSlot: String
    },
    data(){
        return {
            confirming: null,
            timePicker: false,
            isMobile
        }
    },
    computed: {
        booking: {
            get(){
                if(this.type === 'selected'){
                    return this.$store.getters.selectedEvent.bookings[this.index]
                }
                return this.$store.getters.eventToCreate.bookings[this.index]
            },
            set(val){
                if(this.type === 'selected'){
                    this.$store.commit('setSelectedEventBooking', {val, index: this.index})
                } else {
                    this.$store.commit('setEventToCreateBooking', {val, index: this.index})
                }
            }
        },
        parsedSlot: {
            get(){
                return this.booking.slot.replace('h', ':')
            },
            set(val){
                this.booking.slot = val.replace(':', 'h')
            }
        },
    },
    methods: {
        confirmBooking(booking){
            this.confirming = booking._id
            this.$store.dispatch('confirmBooking', booking._id)
                .then(() => {
                    booking.confirmed = true
                    this.confirming = null
                })
        },
        changeTime(){
            this.$store.dispatch('changeTime', this.booking)
        },
        deleteBooking(){
            let confirm = window.confirm('Êtes-vous sûr(e) de vouloir supprimer cette réservation ?')
            if(confirm){
                this.$store.dispatch('deleteBooking', this.booking)
            }
        }
    },
    watch: {
        datePicker(){
            if(!this.datePicker){
                this.$store.getters.eventToCreate.bookings[this.index].slot = this.defaultSlot
            }
        }
    }
}
</script>
<template>
    <v-dialog
        width="50%"
        class="overflow-y-hidden"
        v-model="dialog"
    >
        <v-card
            class="d-flex flex-column overflow-y-hidden"
            height="90vh"
        >
            <v-toolbar
                dark
                color="primary"
                height="60px"
                style="flex: 0"
            >
                <v-card-title>
                    <span v-if="selectedEvent">
                        Réservation | {{moment(selectedEvent.start).locale('fr').format('dddd D MMMM YYYY')}}
                    </span>
                    <span v-else-if="eventToCreate">
                        Réservation<span v-if="eventToCreate.start"> | {{ moment(eventToCreate.start).locale('fr').format('dddd D MMMM YYYY')}}</span>
                    </span>
                </v-card-title>
                <v-spacer />
                <v-icon
                    color="error"
                    v-if="selectedEvent"
                    @click="deleteRoom"
                >
                    mdi-delete
                </v-icon>
                <v-icon class="ml-4" @click="$store.commit('setHotelRoomDialog', false)">
                    mdi-close
                </v-icon>
            </v-toolbar>
            <v-card-text class="flex overflow-y-auto">
                <v-row
                    v-if="selectedEvent || eventToCreate"
                    class="mt-4"
                >
                    <v-col cols="6">
                        <v-menu
                            v-if="selectedEvent"
                            offset-y
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="selectedEvent"
                                    v-model="selectedEventParsedStart"
                                    label="Date d'arrivée"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                first-day-of-week="1"
                                v-if="selectedEvent"
                                v-model="selectedEvent.start"
                            />
                        </v-menu>
                        <v-menu v-if="eventToCreate" offset-y>
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="eventToCreate"
                                    v-model="eventToCreateParsedStart"
                                    label="Date d'arrivée"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                v-if="eventToCreate"
                                v-model="eventToCreate.start"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-menu
                            v-if="selectedEvent"
                            offset-y
                        >
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="selectedEvent"
                                    v-model="selectedEventParsedEnd"
                                    label="Date de départ"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                first-day-of-week="1"
                                v-if="selectedEvent"
                                v-model="selectedEvent.end"
                            />
                        </v-menu>
                        <v-menu v-if="eventToCreate" offset-y>
                            <template #activator="{on, attrs}">
                                <v-text-field
                                    v-on="on"
                                    v-bind="attrs"
                                    v-if="eventToCreate"
                                    v-model="eventToCreateParsedEnd"
                                    label="Date de départ"
                                />
                            </template>
                            <v-date-picker
                                no-title
                                locale="fr"
                                v-if="eventToCreate"
                                v-model="eventToCreate.end"
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <div v-if="selectedEvent">
                    <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                        <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                        <div class="ml-2">Détails  {{ selectedEvent.source === 'web' ? '- Réservation Web' : '' }}</div>
                        <v-spacer />
                        <v-switch color="success" :label="selectedEvent.paid ? 'Payé' : 'Non payé'" hide-details v-model="selectedEvent.paid" />
                        <v-btn @click="!selectedEvent.confirmed ? confirmBooking(selectedEvent) : null" small class="ml-4" v-if="selectedEvent.source === 'web'" :color="selectedEvent.confirmed ? 'success' : 'error'">
                            <v-progress-circular v-if="confirming" indeterminate size="20" />
                            <span v-else-if="selectedEvent.confirmed">Confirmé</span>
                            <span v-else>Non confirmé</span>
                        </v-btn>
                        <v-tooltip bottom v-if="selectedEvent">
                            <template #activator="tooltip3">
                                <v-btn icon color="white" @click="generateSheet" :loading="generatingSheet" v-on="tooltip3.on" v-bind="tooltip3.attrs">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Imprimer feuille de séjour
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="tooltip">
                                <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                    <template #activator="menu">
                                        <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="selectedEvent.cancelled || selectedEvent.noShow ? 'error' : '#999'">
                                            <v-icon v-if="selectedEvent.cancelled === true">mdi-cancel</v-icon>
                                            <v-icon v-else-if="selectedEvent.noShow === true">mdi-emoticon-cry-outline</v-icon>
                                            <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-checkbox label="No show" v-model="selectedEvent.noShow"></v-checkbox>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-checkbox label="Annulé" v-model="selectedEvent.cancelled"></v-checkbox>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <span>Statut</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card class="mx-4 pt-4" style="margin-top: -15px">
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Nom"
                                        v-model="selectedEvent.name"
                                    />
                                </v-col>
                                <v-col cols="6" class="d-flex align-center justify-end">
                                    <v-text-field
                                        label="Prénom"
                                        v-model="selectedEvent.firstName"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.email"
                                        label="Email"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.phone"
                                        label="Téléphone"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.address"
                                        label="Adresse"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.zipCode"
                                        label="Code postal"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.city"
                                        label="Ville"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="selectedEvent.country"
                                        label="Pays"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="number" min="0" v-model.number="selectedEvent.adultPax" label="Nb. adultes" />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="number" min="0" v-model.number="selectedEvent.childrenPax" label="Nb. enfants" />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        type="number"
                                        min="0"
                                        v-model.number="selectedEvent.petsPax"
                                        label="Nb. animaux"
                                        persistent-hint
                                        hint="supp. 20€/nuit/animal"
                                    />
                                </v-col>
                                <v-col cols="1">
                                    <b>Tarif:</b>
                                </v-col>
                                <v-col cols="11">
                                    <v-radio-group class="mt-0" v-model="selectedEvent.rate">
                                        <v-radio value="junior" label="Long Bois Suite Junior - 2 personnes (350€/jour)" />
                                        <v-radio value="jolyrouge" label="Joly Rouge - 4 personnes (450€/jour)" />
                                        <v-radio value="pepindoree" label="Pépin Dorée - 6 personnes (550€/jour)" />
                                        <v-radio value="pommecheval" label="Pomme de Cheval et Pomme de Suie - 10 personnes (650€/jour)" />
                                    </v-radio-group>
                                </v-col>
                                <v-col :cols="selectedEvent.source === 'web' ? '6' : '12'">
                                    <v-textarea
                                        outlined
                                        rows="7"
                                        v-model="selectedEvent.notes"
                                        label="Notes"
                                    />
                                </v-col>
                                <v-col cols="6" v-if="selectedEvent.source === 'web'">
                                    <v-textarea
                                        outlined
                                        rows="7"
                                        readonly
                                        v-model="selectedEvent.guestNotes"
                                        label="Notes Client"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex justify-end">
                                    <span><b>Total: {{ total }}</b></span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div v-else-if="eventToCreate">
                    <v-toolbar dense dark class="mt-4" style="z-index: 10" elevation="0" color="primary">
                        <img :src="require('@/assets/logo-footer.svg')" style="height: 50%; fill: white" />
                        <div class="ml-2">Détails</div>
                        <v-spacer />
                        <v-switch hide-details color="success" :label="eventToCreate.paid ? 'Payé' : 'Non payé'" v-model="eventToCreate.paid" />
                        <v-tooltip bottom>
                            <template #activator="tooltip">
                                <v-menu offset-y v-on="tooltip.on" v-bind="tooltip.attrs">
                                    <template #activator="menu">
                                        <v-btn v-on="{...tooltip.on, ...menu.on}" v-bind="menu.attrs" icon :color="eventToCreate.cancelled || event.toCreate.noShow ? 'error' : '#999'">
                                            <v-icon v-if="eventToCreate.noShow === true">mdi-emoticon-cry-outline</v-icon>
                                            <v-icon v-else>mdi-emoticon-happy-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-checkbox label="No show" v-model="eventToCreate.noShow"></v-checkbox>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <span>Statut</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card class="mx-4 pt-4" style="margin-top: -15px">
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Nom"
                                        v-model="eventToCreate.name"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6" class="d-flex align-center justify-end">
                                    <v-text-field
                                        label="Prénom"
                                        v-model="eventToCreate.firstName"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.email"
                                        label="Email"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.phone"
                                        label="Téléphone"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.address"
                                        label="Adresse"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.zipCode"
                                        label="Code postal"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.city"
                                        label="Ville"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="eventToCreate.country"
                                        label="Pays"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="number" min="0" v-model.number="eventToCreate.adultPax" label="Nb. adultes" />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="number" min="0" v-model.number="eventToCreate.childrenPax" label="Nb. enfants" />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        type="number"
                                        min="0"
                                        v-model.number="eventToCreate.petsPax"
                                        label="Nb. animaux"
                                        persistent-hint
                                        hint="supp. 20€/nuit/animal"
                                    />
                                </v-col>
                                <v-col cols="1">
                                    <b>Tarif:</b>
                                </v-col>
                                <v-col cols="11">
                                    <v-radio-group class="mt-0" v-model="eventToCreate.rate">
                                        <v-radio value="junior" label="Long Bois Suite Junior - 2 personnes (350€/jour)" />
                                        <v-radio value="jolyrouge" label="Joly Rouge - 4 personnes (450€/jour)" />
                                        <v-radio value="pepindoree" label="Pépin Dorée - 6 personnes (550€/jour)" />
                                        <v-radio value="pommecheval" label="Pomme de Cheval et Pomme de Suie - 10 personnes (650€/jour)" />
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12">
                                    <v-col :cols="eventToCreate.source === 'web' ? '6' : '12'">
                                        <v-textarea
                                            outlined
                                            rows="7"
                                            v-model="eventToCreate.notes"
                                            label="Notes"
                                        />
                                    </v-col>
                                    <v-col cols="6" v-if="eventToCreate.source === 'web'">
                                        <v-textarea
                                            outlined
                                            rows="7"
                                            readonly
                                            v-model="eventToCreate.guestNotes"
                                            label="Notes Client"
                                        />
                                    </v-col>
                                </v-col>
                                <v-col cols="12" class="d-flex justify-end">
                                    <span><b>Total: {{ total }}</b></span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions
                style="height: 60px"
                class="d-flex justify-end"
            >
                <v-btn
                    color="error"
                    @click="dialog = false"
                >
                    Annuler
                </v-btn>
                <v-btn
                    color="success"
                    @click="selectedEvent ? $store.dispatch('updateHotelRoom') : $store.dispatch('createHotelRoom')"
                    class="ml-4"
                >
                    Sauvegarder
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
    data(){
        return {
            customToolbar: [ 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ],
            startTimePicker: false,
            endTimePicker: false,
            confirming: false,
            generatingSheet: false
        }
    },
    computed: {
        total(){
            let rate = 0;
            let event = this.selectedEvent ? {...this.selectedEvent} : {...this.eventToCreate};
            let totalDays = moment(event.end).diff(moment(event.start), 'days');
            if(event.rate === 'junior'){
                rate += 350 * totalDays;
            } else if (event.rate === 'jolyrouge') {
                rate += 450 * totalDays;
            } else if (event.rate === 'pepindoree'){
                rate += 550 * totalDays;
            } else if (event.rate === 'pommecheval'){
                rate += 650 * totalDays;
            }
            if(event.adultPax){
                rate += totalDays * event.adultPax * 2.3;
            }
            if(event.petsPax){
                rate += event.petsPax * totalDays * 20;
            }
            return rate.toFixed(2);
        },
        eventToCreateParsedStart(){
            return this.moment(this.eventToCreate?.start).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedStart(){
            return this.moment(this.selectedEvent?.start).locale('fr').format('D MMM YYYY')
        },
        eventToCreateParsedEnd(){
            return this.moment(this.eventToCreate?.end).locale('fr').format('D MMM YYYY')
        },
        selectedEventParsedEnd(){
            return this.moment(this.selectedEvent?.end).locale('fr').format('D MMM YYYY')
        },
        dialog: {
            get(){
                return this.$store.getters.hotelRoomDialog
            },
            set(val){
                this.$store.commit('setHotelRoomDialog', val)
            }
        },
        selectedEvent: {
            get(){
                return this.$store.getters.selectedEvent
            },
            set(val){
                this.$store.commit('setSelectedEvent', val)
            }
        },
        eventToCreate: {
            get(){
                return this.$store.getters.eventToCreate
            },
            set(val){
                this.$store.commit('setEventToCreate', val)
            }
        },
        moment(){
            return this.$store.getters.moment
        }
    },
    methods: {
        deleteRoom(){
            let confirm = window.confirm('Êtes-vous sûr(e) de vouloir supprimer cette réservation ?')
            if(confirm){
                this.$store.dispatch('deleteRoom', this.selectedEvent)
            }
        },
        confirmBooking(booking){
            this.confirming = true
            this.$store.dispatch('confirmHotelBooking', booking._id)
            .then(() => {
                this.selectedEvent.confirmed = true
                this.confirming = false
            })
        },
        generateSheet(){
            this.generatingSheet = true;
            this.axios.get(`hotel-rooms/generate-sheet/${this.selectedEvent._id}`, {responseType: 'arraybuffer'}).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Feuille de Séjour - ${this.selectedEvent.firstName} ${this.selectedEvent.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                this.generatingSheet = false;
            });
        }
    }
}
</script>